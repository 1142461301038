import React from 'react'

function EmailCEO() {
  return (
    <div className='em_blue_bg'>
        <h1>NOT SATISFIED?</h1>
        <p>If we haven't answered your questions through the above channels, Pradco's CEO wants to know.</p>
        <a href='#emailCEO'><button id='emailceo'>Email the CEO</button></a>
    </div>
  )
}

export default EmailCEO