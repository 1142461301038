import React from "react";
import world_class from "../../assets/about/world_class.png";
import hquality from "../../assets/about/hquality.png";

function Worldclass() {
  return (
    <div>
      <div className="worldclass">
        <img src={world_class} id='w_img'/>
        <div id="w_content">
          <h2 id="w_h2">
            COMMITTED TO WORLD-CLASS MANUFACTURING, UNPARALLELED CUSTOMER
            SERVICE
          </h2>
          <br />
          <p id="w_p">
            We base our success on Pradco’s unwavering commitment to technical
            excellence, world-class manufacturing and A+ customer service. To
            that end, we work closely with specialised distributors and offer
            products that set high quality standards in a broad range of fluid
            handling applications: spray finishing and paint circulation,
            lubrication, sealants and adhesives, sanitary processing and power
            application equipment for contractors. Additionally, Pradco’s
            ongoing investments in fluid management and control continue to
            provide innovative solutions to a diverse global market.
          </p>
        </div>
      </div>

      <div id="h_quality">
        <div id="w_content">
          <h2 id="w_h2">A HIGH QUALITY, EFFICIENT AND ENGAGED WORKFORCE</h2>
          <br />
          <p id="w_p">
            India is home to 90 percent of our manufacturing production, where
            we can leverage our highly efficient, engaged workforce. Our sales,
            marketing, engineering and manufacturing teams work closely with our
            customers and use their feedback to improve our products and
            equipment continuously. The result: satisfied customers who are
            incredibly loyal to the Pradco brand
          </p>
        </div>
        <img src={hquality}  id='w_img'/>
      </div>
    </div>
  );
}

export default Worldclass;
