import React from "react";
import decks from "../../assets/home_owner/decks.png";
import interior_walls from "../../assets/home_owner/interior_walls.png";
import exterior_walls from "../../assets/home_owner/exterior_walls.png";
import cabinets from "../../assets/home_owner/cabinets.png";
import ceilings from "../../assets/home_owner/ceilings.png";
import doors from "../../assets/home_owner/doors.png";
import furniture from "../../assets/home_owner/furniture.png";
import railings from "../../assets/home_owner/railings.png";

function Cards() {
  return (
    <div className="background-grey">
      <div className="card-list">
        {/* CARD 1  */}

        <div className="card">
        
            <img src={exterior_walls} className="card-image" />
            <div className="card34_title">EXTERIOR WALLS</div>
            <p className="card_content">
              Add value and curb appeal to the exterior of your home with a
              fresh coat of paint. Learn how to save time while still achieving
              proper paint coverage.
            </p>
     
        </div>

        {/* CARD 2 */}
        <div className="card">
         
            <img src={interior_walls} className="card-image" />
            <div className="card34_title">INTERIOR WALLS</div>
            <p className="card_content">
              With a Pradco sprayer, you can change the look of any room in the
              house in a fraction of the time it would take you to paint it by
              hand with a roller or brush.
            </p>
        
        </div>

        {/* CARD 3 */}
        <div className="card">
        
            <img src={decks} className="card-image" />
            <div className="card34_title">DECKS</div>
            <p className="card_content">
              Applying a new coat of finish to your deck will protect the wood
              from the sun and harsh weather and make all the difference in the
              appearance of your entire home.
            </p>
         
        </div>

        {/* CARD 4 */}
        <div className="card">
       
            <img src={cabinets} className="card-image" />
            <div className="card34_title">CABINETS</div>
            <p className="card_content">
              Painting or staining your cabinets can completely transform the
              look of your kitchen or bathroom. Since many rooms with cabinets
              usually get a lot of traffic and activity, you’ll want to get this
              project done fast.
            </p>
         
        </div>
      </div>

      <div className="card-list">
        {/* CARD 1  */}

        <div className="card">
        
            <img src={ceilings} className="card-image" />
            <div className="card34_title">CEILINGS</div>
            <p className="card_content">
              Painting textured ceilings with paint rollers takes time and can
              cause the texture to flake off from the surface. Using an airless
              sprayer to paint your ceilings is easier and saves you time.
            </p>
  
        </div>

        {/* CARD 2 */}
        <div className="card">
  
            <img src={doors} className="card-image" />
            <div className="card34_title">DOORS</div>
            <p className="card_content">
              Painting trim and doors in a room can often be overlooked. A new
              or updated colour like white or stain can put the finishing touch
              on a professional-looking interior painting project.
            </p>
      
        </div>

        {/* CARD 3 */}
        <div className="card">
     
            <img src={furniture} className="card-image" />
            <div className="card34_title">FURNITURE</div>
            <p className="card_content">
              Whether it’s a garage sale find or a chair or dresser you already
              own, a fresh coat of paint can give new life to outdated or
              tired-looking furniture in your home.
            </p>

        </div>

        {/* CARD 4 */}
        <div className="card">
         
            <img src={railings} className="card-image" />
            <div className="card34_title">RAILINGS</div>
            <p className="card_content">
              Railings take a lot of abuse. Keep them looking their best with a
              fresh coat of paint or stain to preserve and protect them from the
              sun and weather.
            </p>
  
        </div>
      </div>
    </div>
  );
}

export default Cards;
