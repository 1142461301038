import React from 'react'
import {Header, Footer,HeaderPC} from '../components'
import Banner from '../components/Contact/banner'
import Sales from '../components/Contact/sales'
import Form from '../components/Contact/form'
import EmailCEO from '../components/Contact/emailceo'
import EmailForm from '../components/Contact/emailceoform'
import CeoMail from '../components/Contact/ceomail'
import '../components/Contact/contact.css'

function Contact() {
  return (
    <div className='mobile_view'>
        <Header/>
        <HeaderPC/>
        <Banner/>
        <Sales/>
    <Form/> 
    <EmailCEO/>
    <CeoMail/>
           <Footer/>
    </div>
  )
}

export default Contact