import React from 'react'
import careers from '../../assets/home/careers.png';

function Careers() {
  return (
    
    <div className="careers">
 
      <div id="career_card">
        <h2 id="career_title1">CAREERS AT PRADCO</h2>
        <hr id="career_line" />
        
       
        <h1 id="career_title2">
          BE A PART OF <br />
          SOMETHING
          <br /> GREAT
        </h1>
        <p id='careers_content'>
          Whether you’re a recent college graduate or professional looking for
          a new challenge, Pradco offers a dynamic workplace and an
          opportunity to create and support products that excite our thousands
          of global customers.
        </p>
        <a id='career_learn' href='/careers'>
    LEARN MORE {'>'}
  </a>
      </div>
      <img src={careers} id='careers_img'/>
    </div>
  )
}

export default Careers