import React from 'react'
import './header_try.css'
import './header.css'
import pradco_logo from '../assets/home/pradco_logo.png';
import profile from '../assets/home/profile.png';

function Header_try() {
  return (
    <div>
   
    <body>
        <nav class="nav-area">
  

            <ul>
                
            <li><a href="https://pradco-dashboard.web.app/" target="_blank" >For Distributors </a></li>
            <a href="/"><img src={pradco_logo} className="Logo_header" alt="logo" /></a>
                <li>
                    <a href="/contact">CONTACT</a>
                </li>
                <li>
                    <a href="/careers">CAREERS</a>
                </li>
                <li>
                    <a href="#">SOLUTIONS</a>
                    <ul>
                    <li>
                            <a className='listitem' href="/homeowner"> Home Owner</a>
                        </li>
                        <li>
                            <a className='listitem' href="/contractor">Contractor</a>
                        </li>
                        <li>
                            <a className='listitem' href="/vehicle">Vehicle Washing & Agri-Tech Equipments
</a>
                        </li>
                        <li>
                            <a  className='listitem' href="/industry">Industrial, Manufacturing & Processing</a>
                            
                        </li>
                    </ul>
                </li>
                
                
                <li>
                    <a href="#">PRODUCTS</a>
                    <ul>
                        <li>
                            <a className='listitem' href="/paintsprayers">Paint Sprayers</a>
                        </li>
                        <li>
                            <a className='listitem' href="/accessories">Accessories</a>
                        </li>
                        <li>
                            <a className='listitem' href="/spray_tip">Spray Tip</a>
                        </li>
                        <li>
                            <a  className='listitem' href="/product_catalogue">Product Catalogue</a>
                            
                        </li>
                      
                    </ul>
                </li>


                <li>
                    <a href="/">HOME</a>
                </li>
            </ul>
        </nav>
    </body>
   
    </div>
  )
}

export default Header_try