import React from 'react'
import spray_tip from '../../assets/spray_tip/spray_tip_banner.png'
import spray_tip_mobile from '../../assets/spray_tip/spray_tip_mobile.png'

function Banner() {
  return (
    <div>
      <div id="banner_mobile_hid">
         <h1 id="abanner_h1">AIRLESS SPRAY TIPS & TIP GUARDS</h1>
      <p id="sbanner_p">
      Choosing the right tip will determine the quality of your finish and help you save a significant amount of time and money. Pradco tips are easy to identify so you use less material and finish your projects faster.
      </p></div>
      <img src={spray_tip} alt="Home Owner" id="banner_soln" />
      <img src={spray_tip_mobile} alt="Home Owner" id="banner_soln_mobile" />
    </div>
  )
}

export default Banner