import React from "react";
import { Link } from "react-router-dom";
import exterior_walls from "../../assets/home_owner/exterior_walls.png";
import homeowner from "../../assets/home/card_1.png";
import card2 from "../../assets/home/card_2.png";
import card3 from "../../assets/agri_pressure/clean.png";
import card4 from "../../assets/home/card_4.png";

function Products() {
  return (
    <div>
      <h1 id="prod_soln_title">PRODUCTS {"&"} SOLUTIONS</h1>
      <p id="prod_title_tag">
        Pradco designs and manufactures fluid handling products and solutions
        for the industries we pursue around the world.
      </p>
      <div className="products_solutions">
        <div className="card-list">
          {/* CARD 1  */}

          <div className="card">
            <a href="/homeowner">
              <img src={exterior_walls} loading="lazy" alt="homeowner" />
              <div className="card34_title">HOMEOWNER</div>
              <p>
                Homeowners and do-it-yourself consumers use our time-saving
                paint sprayers to finish weekend projects inside and outside of
                the home. Doors, trim, decks, ceilings, fences, and more.
              </p>
            </a>
          </div>

          {/* CARD 2 */}
          <div className="card">
            <a href="/contractor">
              <img src={card2} loading="lazy" alt="contractor" />
              <div className="card34_title">CONTRACTOR</div>
              <p>
                Professional contractors in a broad range of specialties
                consider Pradco their go-to place, for hardy and reliable
                equipment that helps them save time and deliver high quality
                results.
              </p>
            </a>
          </div>

          {/* CARD 3 */}
          <div className="card">
            <a href="/vehicle">
              <img src={card3} loading="lazy" alt="vehicle" />
              <div className="card34_title">
                VEHICLE WASHING & AGRI-TECH EQUIPMENTS
              </div>
              <p>
                Customers rely on our expertise in pressure washers that are
                designed to achieve maximum cleaning results with the minimum of
                energy and time.
              </p>
            </a>
          </div>

          {/* CARD 4 */}
          <div className="card">
            <a href="/industry">
              <img src={card4} loading="lazy" alt="Industrial manufacturing" />
              <div className="card34_title">
                INDUSTRIAL, MANUFACTURING {"&"} <br /> PROCESSING
              </div>
              <p>
                For customers in industries such as automotive, aerospace,
                packaging, sanitary and many others.
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
