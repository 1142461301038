import React from 'react'
import Banner from '../components/About/banner'
import {Header,Footer, HeaderPC} from '../components'
import Worldclass from '../components/About/worldclass'
import Promise from '../components/About/promise'
import Benefit from '../components/About/benefit'
import '../components/About/about.css'

function About() {
  return (
    <div className='mobile_view'>
        <Header/>
        <HeaderPC/>
        <Banner/>
        <Worldclass/>
        <Promise/>
        <Benefit/>
        <Footer/>
    </div>
  )
}

export default About