import React from 'react'

function SharedValues() {
  return (
    <div>
        <h2 id='center'>Shared Values</h2>
        <h1 className='shared_title'>What you work for should reflect <span id='red-text'>what you stand for.</span></h1>

        <p id='cartag'>For everything we create, we consider its impact — on our customers, our colleagues, and our planet.
 The same innovation that goes into making our products goes into taking on issues we care about deeply, such as accessibility, equity, privacy, and the environment.

Everyone joins SprayX for a reason. Often it’s because 
they found a company that aligns with their own values.</p>
<br/>
    </div>
  )
}

export default SharedValues