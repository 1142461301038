import React from "react";
import about_banner from "../../assets/about/about_banner.png";
import about_mob from "../../assets/about/about_mob.png";

function Banner() {
  return (
    <div>
      <div id="banner_mobile_hid">
      <h1 id="about_b_h">ABOUT PRADCO</h1>
      <p id="about_p">
        Pradco manufactures and markets premium move, measure, control, spray a
        wide variety of fluid and powder materials, and also dispense equipment.
      </p></div>
      <img src={about_banner} alt="Industrial Manufacturing" id="banner_soln" />
      <img
        src={about_mob}
        alt="Industrial Manufacturing"
        id="banner_soln_mobile"
      />

      <h1 id="title_1">
      A PROUD INDIAN MANUFACTURER
      </h1>
      
    </div>
  );
}

export default Banner;
