import React from 'react'

function Title() {
  return (
    <div>
        <h3 id="center">Life at Pradco</h3>
      <h1 id="title_1">Join our community and <br/>
help define it.
</h1>
      <p id="cartag">Explore a collaborative culture of inclusion, growth, and originality, supported by resources that make a difference in your life.</p>
    </div>
  )
}

export default Title