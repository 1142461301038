import React from "react";
import s490 from "../../assets/catalogue/machine1.png";
import s630 from "../../assets/catalogue/machine_2.png";
import eb230 from "../../assets/catalogue/machine3.png";

function SprayMachines() {
  return (
    <div>
      <h1 id="pg_title">PAINT SPRAYERS</h1>
      <p id="hoses_p">
        For high production rates and top performance, count on durable,
        easy-to-maintain Pradco battery and electric airless sprayers.
      </p>
      <br />
      <br />

      <div className="ps_cards">
        {/* CARD */}
        <div className="s_card">
          <img src={s490} alt="SprayX 490" />
          <h3>
            SprayX 490
            <br />
            (For Small Applications)
          </h3>
        </div>

        <div className="s_card">
          <img src={s630} alt="SprayX 490" />
          <h3>
          SprayX 630<br/>
(For Medium Applications)
          </h3>
        </div>

        <div className="s_card">
          <img src={eb230} alt="SprayX 490" /><br/><br/>
                    <h3>
          SprayX EB230<br/>
(For Large Applications)
          </h3>
        </div>




      </div>


    </div>
  );
}

export default SprayMachines;
