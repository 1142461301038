import React from 'react'
import chemicals from '../../assets/industry/chemicals.png'
import foam from '../../assets/industry/foam.png'
import fluid from '../../assets/industry/fluid.png'
import liquid from '../../assets/industry/liquid.png'
import lubrication from '../../assets/industry/lubrication_4.png'
import mortar2 from '../../assets/industry/mortar2.png'
import protective from '../../assets/industry/protective.png'
import sealants from '../../assets/industry/sealants.png'


function Cards() {
  return (
     <div className="ibackground-grey">
    <div className="card-list">
      {/* CARD 1  */}

      <div className="card">
   
          <img src={chemicals} className="card-image" />
          <div className="card34_title">CHEMICALS</div>
          <p className="card_content">
          We design and build a broad array of equipment specifically for transferring and injecting chemicals in manufacturing and oil and natural gas applications.
          </p>
       
      </div>

      {/* CARD 2 */}
      <div className="card">
   
          <img src={foam} className="card-image" />
          <div className="card34_title">FOAM</div>
          <p className="card_content">
          Pradco designs and builds polyurethane foam equipment and products specifically for manufacturing applications. Check out our spray and pour foam products.
          </p>
       
      </div>

      {/* CARD 3 */}
      <div className="card">
   
          <img src={fluid} className="card-image" />
          <div className="card34_title">GENERAL FLUID TRANSFER</div>
          <p className="card_content">
          Pradco designs and builds a wide range of equipment designed to handle scores of industrial fluids such as inks, colourants, paint, abrasives and more.
          </p>
       
      </div>

      {/* CARD 4 */}
      <div className="card">
   
          <img src={liquid} className="card-image" />
          <div className="card34_title">LIQUID COATING</div>
          <p className="card_content">
          Pradco designs and manufactures industrial paint kitchen, spray booth and proportioning equipment for metal, plastic and wood applications.
          </p>
       
      </div>
    </div>

    <div className="card-list">
      {/* CARD 1  */}

      <div className="card">
   
          <img src={lubrication} className="card-image" />
          <div className="card34_title">LUBRICATION</div>
          <p className="card_content">
          Pradco has been designing and building modern lubrication systems that serve the in-plant manufacturing industry for decades.
          </p>
       
      </div>

      {/* CARD 2 */}
      <div className="card">
   
          <img src={mortar2} className="card-image" />
          <div className="card34_title">MORTAR</div>
          <p className="card_content">
          Pradco designs and builds mortar equipment such as concrete mixers and pumps that are ideal for high volume precast applications.
          </p>
       
      </div>

      {/* CARD 3 */}
      <div className="card">
   
          <img src={protective} className="card-image" />
          <div className="card34_title">PROTECTIVE COATINGS</div>
          <p className="card_content">
          Pradco designs and builds a broad array of equipment for applying protective coatings, wear-resistant coatings and fireproofing materials in manufacturing applications.
          </p>
       
      </div>

      {/* CARD 4 */}
      <div className="card">
   
          <img src={sealants} className="card-image" />
          <div className="card34_title">SEALANTS & ADHESIVES</div>
          <p className="card_content">
          Pradco provides a complete line of pumping, metering and dispensing equipment for sealants and adhesives.
          </p>
       
      </div>
    </div></div>
  )
}

export default Cards