import React from 'react'
import { Footer, Header, HeaderPC } from '../components'
import AirlessSprayTips from '../components/SprayTip/airless_stips'
import Banner from '../components/SprayTip/banner'
import RightTip from '../components/SprayTip/right_tip'
import '../components/SprayTip/spray_tip.css'

function SprayTip() {
  return (
    <div className='mobile_view'>
        <Header/>
        <HeaderPC/>
        <Banner/>
        <AirlessSprayTips/>
        <RightTip/>

        <Footer/>
    </div>
  )
}

export default SprayTip