import React from "react";
import dispense from "../../assets/vehicle/dispense.png";
import pumps from "../../assets/vehicle/pumps.png";
import hose from "../../assets/vehicle/hose.png";
import undercoating from "../../assets/vehicle/undercoating.png";
import car_wash from "../../assets/vehicle/car_wash.png";
import commercial from "../../assets/vehicle/commercial.png";
import versatility from "../../assets/vehicle/versatility.png";
import food_industry from "../../assets/vehicle/food_industry.png";
import agri from "../../assets/vehicle/agri.png";

function Cards() {
  return (
    <div className="vbackground-grey">
      <h1 id="vtitle_1">
        MULTIPLE PRESSURE WASHER OPTIONS — TAILOR
        ONE FOR YOU
      </h1>
      <p id="washer-tag">
        Pradco pressure washers are designed to achieve maximum cleaning results
        with the minimum of energy and time. At the same time, they reduce the
        level of waste water pollution caused by mineral oil residues thanks to
        their special formulas that are easy to filter and separate.{" "}
      </p>
      <div className="card-list">
        {/* CARD 1  */}

        <div className="card">
          <img src={commercial} className="card-image" />
          <div className="card34_title">
            CLEANING & PROTECTING COMMERCIAL VEHICLES
          </div>
          <p className="card_content">
            High-performance pressure washers for powerful cleaning of stubborn
            dirt on roads and building sites - can be used with cold and hot
            water high-pressure cleaners.
          </p>
        </div>

        {/* CARD 2 */}
        <div className="card">
          <img src={versatility} className="card-image" />
          <div className="card34_title">
            VERSATILITY FOR INDUSTRIAL APPLICATIONS
          </div>
          <p className="card_content">
            Fast-acting, gentle cleaning pressure washers which also remove
            stubborn dirt such as oils, grease and rust.
          </p>
        </div>

        {/* CARD 3 */}
        <div className="card">
          <img src={food_industry} className="card-image" />
          <div className="card34_title">
            CLEANLINESS AND HYGIENE IN FOOD INDUSTRY
          </div>
          <p className="card_content">
            Effective cleaning and disinfectants with approval for use with
            foodstuffs in accordance with the health industry standards.
          </p>
        </div>

        {/* CARD 4 */}
        <div className="card">
          <img src={car_wash} className="card-image" />
          <div className="card34_title">
            POLISHED SURFACES FOR YOUR CAR CLEANING
          </div>
          <p className="card_content">
            Clean your bikes and cars, by saving water & time than the
            traditional way of cleaning the vehicles.
          </p>
        </div>
      </div>

      <div className="card-list">
        {/* CARD 5  */}

        <div className="card">
          <img src={dispense} className="card-image" />
          <div className="card34_title">DISPENSE VALVES</div>
          <p className="card_content">
            Pradco’s grease valves and guns are engineered to operate for years
            in tough environments. Durable and reliable Pradco grease meters and
            dispense valves are ideal for high pressure grease dispensing.
          </p>
        </div>

        {/* CARD 6 */}
        <div className="card">
          <img src={pumps} className="card-image" />
          <div className="card34_title">FLEXIBLE PUMPS & DISPENSE PACKAGES</div>
          <p className="card_content">
            Pradco’s of flexible pumps are designed to handle all automotive and
            equipment fluid needs including oil, grease, coolant, window wash
            fluid and more. Our reliable pumps are engineered to operate for
            years in demanding environments
          </p>
        </div>

        {/* CARD 7 */}
        <div className="card">
          <img src={agri} className="card-image" />
          <div className="card34_title">AIRLESS SPRAY IN AGRI-TECH</div>
          <p className="card_content">
            Whether you’re a farmer or a contractor focused on small or a large
            agricultural tackling the biggest projects every day, Pradco offers
            you the best, most extensive line of spray equipments to meet your
            needs.
          </p>
        </div>

        {/* CARD 8 */}
        <div className="card">
          <img src={undercoating} className="card-image" />
          <div className="card34_title">
            UNDERCOATING & RUSTPROOFING SPRAY GUNS
          </div>
          <p className="card_content">
            Pradco’s undercoating spray guns & pumps mount directly to drums and
            barrels, making spraying simple. Our pumps reduces downtime and
            lowers any repair costs.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Cards;
