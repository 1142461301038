import React from 'react'

function Terms() {
  return (
    <div className='terms'>
          <h2 class="introduction">TERMS OF SERVICE</h2>
           <div className='parallel'>
               <div id='l_content'>
        <h2 id="acceptanceofterms" className='headings'>ACCEPTANCE OF TERMS</h2>
        <p>
          Welcome to Pradco. Pradco provides its service to you, subject to the
          following Terms of Service ("ToS"), which may be updated by us from
          time to time without notice to you. You can review the most current
          version of the TOS at any time
          at: http://www.pradco.in/TermsOfService,
          http://www.sprayx.org/TermsOfService
        </p>

        <h2 id="descriptionofservice"  className='headings'>DESCRIPTION OF SERVICE</h2>
        <p>
          Pradco currently provides users with access to a collection of online
          resources (the "Service"). Unless explicitly stated otherwise, any new
          features that augment or enhance the current Service, including the
          release of new Pradco properties, shall be subject to the TOS. You
          understand and agree that the Service is provided "AS-IS".
        </p>

        <h2 id="conduct"  className='headings'>CONDUCT</h2>
        <p>
          Under no circumstances will Pradco be liable in any way for any
          Content, including, but not limited to, for any errors or omissions in
          any Content, or for any loss or damage of any kind incurred as a
          result of the use of any Content posted, Emailed or otherwise
          transmitted via the Service. You agree to not use the Service to:
          <ol type='a'>
          <li>
          Upload, post, Email or otherwise transmit any Content that is
          unlawful, harmful, threatening, abusive, harassing, tortious,
          defamatory, vulgar, obscene, libellous, invasive of another's privacy,
          hateful, or racially, ethnically or otherwise objectionable;</li>
          
          <li>Harm minors in any way;</li> 
          <li> Impersonate any person or entity, including, but
          not limited to, a Pradco official, or falsely state or otherwise
          misrepresent your affiliation with a person or entity;</li>
          <li>Forge headers or otherwise manipulate identifiers in order to disguise the
          origin of any Content transmitted through the Service;</li>
          <li>Upload, post, Email or otherwise transmit any Content that you do not have a
          right to transmit under any law or under contractual or fiduciary
          relationships (such as inside information, proprietary and
          confidential information learned or disclosed as part of employment
          relationships or under nondisclosure agreements);</li>
          <li>Upload, post, Email or otherwise transmit any Content that infringes any patent,
          trademark, trade secret, copyright or other proprietary rights
          ("Rights") of any party;</li> 
          <li>Upload, post, Email or otherwise transmit
          any unsolicited or unauthorised advertising, promotional materials,
          "junk mail," "spam," "chain letters," "pyramid schemes," or any other
          form of solicitation;</li>
          <li>Upload, post, Email or otherwise transmit any
          material that contains software viruses or any other computer code,
          files or programs designed to interrupt, destroy or limit the
          functionality of any computer software or hardware or
          telecommunications equipment; </li> 
          <li>Interfere with or disrupt the Service
          or servers or networks connected to the Service, or disobey any
          requirements, procedures, policies or regulations of networks
          connected to the Service;</li>
          <li>Intentionally or unintentionally violate
          any applicable local, state, national or international law, including,
          but not limited to, regulations promulgated by the Indian Securities
          and Exchange Commission, any rules of any national or other securities
          exchange, including, without limitation, the National Stock Exchange
          and any regulations having the force of law.</li>
          </ol>
           You agree that you must
          evaluate, and bear all risks associated with, the use of any Content,
          including any reliance on the accuracy, completeness, or usefulness of
          such Content. In this regard, you acknowledge that you may not rely on
          any Content created by Pradco or submitted to Pradco.
        </p>

        <h2 id="specialadmonitionsforinternationaluse"  className='headings'>SPECIAL ADMONITIONS FOR INTERNATIONAL USE</h2>
        <p>
          Recognising the global nature of the Internet, you agree to comply
          with all local rules regarding online conduct and acceptable Content.
          Specifically, you agree to comply with all applicable laws regarding
          the transmission of technical data exported from the India or the
          country in which you reside.
        </p>

        <h2 id="indemnity" className='headings' >INDEMNITY</h2>
        <p>
          You agree to indemnify and hold Pradco, and its subsidiaries,
          affiliates, officers, agents or other partners, and employees,
          harmless from any claim or demand, including reasonable attorneys’
          fees, made by any third party due to or arising out of Content you
          submit, post to or transmit through the Service, your use of the
          Service, your connection to the Service, your violation of the TOS, or
          your violation of any rights of another.
        </p>

        <h2 id="noresale"  className='headings'>NO RESALE OF SERVICE</h2>
        <p>
          You agree not to reproduce, duplicate, copy, sell, resell or exploit
          for any commercial purposes, any portion of the Service, use of the
          Service, or access to the Service.
        </p>
        <h2 id="modificationstoservice" className='headings'>MODIFICATIONS TO SERVICE</h2>
        <p>
          Pradco reserves the right at any time and from time to time to modify
          or discontinue, temporarily or permanently, the Service (or any part
          thereof) with or without notice. You agree that Pradco shall not be
          liable to you or to any third party for any modification, suspension
          or discontinuance of the Service.
        </p>
        <h2 id='links' className='headings'>LINKS</h2>
        <p>
          You may not link to this site without execution of a Linking Agreement
          sent to contact@pradco.in, contact@sprayx.org.
        </p>

        <h2 id='proprietary_rights' className='headings'>PRADCO'S PROPRIETARY RIGHTS</h2>
        <p>
          You acknowledge and agree that the Service and any necessary software
          used in connection with the Service ("Software") contain proprietary
          and confidential information that is protected by applicable
          intellectual property and other laws. Except as expressly authorised
          by Pradco, you agree not to modify, rent, lease, loan, sell,
          distribute or create derivative works based on the Service or the
          Software, in whole or in part. Pradco grants you a personal,
          non-transferable and non-exclusive right and licence to use the object
          code of its Software on a single computer; provided that you do not
          (and do not allow any third party to) copy, modify, create a
          derivative work of, reverse engineer, reverse assemble or otherwise
          attempt to discover any source code, sell, assign, sublicense, grant a
          security interest in or otherwise transfer any right in the Software.
          You agree not to modify the Software in any manner or form, or to use
          modified versions of the Software, including (without limitation) for
          the purpose of obtaining unauthorised access to the Service. You agree
          not to access the Service by any means other than through the
          interface that is provided by Pradco for use in accessing the Service.
        </p>

        <h2  id='disclaimer' className='headings'>DISCLAIMER OF WARRANTIES</h2>
        <p>
          You Expressly Understand and agree that : a. Your use of the Service is
          at your sole risk. The service is provided on an "as is" and "as
          available" basis. Pradco expressly disclaims all warranties of any
          kind, whether express or implied, including, but not limited to the
          implied warranties of merchantability, fitness for a particular
          purpose and non-infringement. b. Pradco makes no warranty that (i) The
          service will meet your requirements, (ii) The service will be uninterrupted, timely, secure, or error-free, (iii) The Results that
          may be obtained from the use of the service will be accurate or reliable,
          (iv) The quality of any products, services, information, or
          other material purchased or obtained by you through the service will
          meet your expectations, and (V) any errors in the software will be corrected.
          c. Any material downloaded or otherwise obtained through the use of the service
          is done at your own discretion and risk and that you will be solely responsible
          for any damage to your computer.
          System or loss of data that results from the download of any such material.
          d. No advice or information, whether oral or written,
          obtained by you from Pradco or through or from the service shall create any warranty not expressly stated in the TOS.
        </p>

        <h2 id='limitation' className='headings'>LIMITATION OF LIABILITY</h2>
        <p>
        You Expressly Understand and agree that : Pradco shal not be liable for any direct, indirect, incidental, special, consequential or exemplary damages,
          including but not limited to, damages for loss of profits,
          goodwill, use,data or other intangible losses (Even if Pradco has
          been advised of the possibility of such damages), Resulting from: (i)
          The use or the inability to use the service; (ii) The cost of
          procurement of substitute goods and service resulting from any goods,
          data, information or services purchased or obtained or messages
          received or transactions entered into through or from the service;
          (iii) Unauthorised access to or alteration of your transmission or
          data; (iv) Statements or conduct of any third party on the service; or
          (v) Any other matter relating to the service.
        </p>

        <h2 id='exclusions' className='headings'>EXCLUSIONS AND LIMITATIONS</h2>
        <p>
          Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidentall or consequential damages. Accordingly,
         some of the above limitations of other sections may not apply to you.
        </p>

        <h2 id='notice' className='headings'>NOTICE</h2>
        <p>
          Notices to you may be made via either Email or regular mail. The
          Service may also provide notices of changes to the TOS or other
          matters by displaying notices or links to notices to you generally on
          the Service.
        </p>

        <h2 id='trademark' className='headings'>TRADEMARK INFORMATION</h2>
        <p>
          Pradco, the Pradco logo, the SprayX logo and other Pradco brand logos
          and product and service names are trademarks of Pradco Inc. (the
          "PradcoMarks"). Without Pradco's prior permission, you agree not to
          display or use in any manner, the Pradco Marks.
        </p>

        <h2 id='copyrights' className='headings'>COPYRIGHTS</h2>
        <p>
          Pradco respects the intellectual property of others, and we ask our
          users to do the same. If you believe that your work has been copied in
          a way that constitutes copyright infringement, please provide the
          relevant information to  contact@pradco.in, contact@sprayx.org.
        </p>

        <h2 id='general_info' className='headings'>GENERAL INFORMATION</h2>
        <p>
          The TOS constitute the entire agreement between you and Pradco and
          govern your use of the Service, superseding any prior agreements
          between you and Pradco other than your GEDI and distributor agreements
          (if applicable). You also may be subject to additional terms and
          conditions that may apply when you use affiliate services, third-party
          content or third-party software. The TOS and the relationship between
          you and Pradco shall be governed by the laws of India
          without regard to its conflict of law provisions. You and Pradco agree
          to submit to the personal and exclusive jurisdiction of the courts
          located within the country. The failure of
          Pradco to exercise or enforce any right or provision of the TOS shall
          not constitute a waiver of such right or provision. If any provision
          of the TOS is found by a court of competent jurisdiction to be
          invalid, the parties nevertheless agree that the court should
          endeavour to give effect to the parties' intentions as reflected in
          the provision, and the other provisions of the TOS remain in full
          force and effect. You agree that regardless of any statute or law to
          the contrary, any claim or cause of action arising out of or related
          to use of the Service or the TOS must be filed within one (1) year
          after such claim or cause of action arose or be forever barred.
        </p>
        <p>
          Copyright © 2022 Pradco Private Limited. All rights reserved.</p><br/><br/></div>

          <div className='quick_links'>
          <a href='#acceptanceofterms'>ACCEPTANCE OF TERMS</a>
          <a  href='#descriptionofservice'>  DESCRIPTION OF SERVICE</a>
          <a  href='#conduct'>  CONDUCT</a>
          <a href='#specialadmonitionsforinternationaluse'>  SPECIAL ADMONITIONS FOR INTERNATIONAL USE</a>
          <a href='#indemnity'>  INDEMNITY</a>
          <a href='#noresale'>  NO RESALE OF SERVICE</a>
          <a href='#modificationstoservice'>  MODIFICATIONS TO SERVICE</a>
          <a href='#links'>  LINKS</a>
          <a href='#proprietary_rights'>  PRADCO'S PROPRIETARY RIGHTS</a>
          <a href='#disclaimer'>  DISCLAIMER OF WARRANTIES</a>
          <a href='#limitation'>   LIMITATION OF LIABILITY</a>
          <a href='#exclusions'>  EXCLUSIONS AND LIMITATIONS</a>
          <a href='#notice'>  NOTICE</a>
          <a href='#trademark'>  TRADEMARK INFORMATION</a>
          <a href='#copyrights'>  COPYRIGHTS</a>
          <a href='#general_info'>  GENERAL INFORMATION</a>
          </div>
          </div>
    </div>
  )
}

export default Terms