export const accordionData = [
  {
    title: 'Where can I buy Pradco & SprayX equipment, repair parts or obtain service?',
    content: `Pradco & SprayX distributors and service centers are your first stop for great technical advice and service. To purchase equipment, parts, or obtain repair of Pradco & SprayX products, conduct a search on one of our customer sites.\n
Contractor: Through Distributor
Vehicle Service & Heavy Equipment: Through Distributor
Industrial, Manufacturing & Processing: Through Distributor`
  },
  {
    title: 'How do I obtain pricing, quotes or information on Pradco & SprayX products?',
    content: `Pradco & SprayX only sells through an authorised distributor network. To locate an authorised Pradco & SprayX dealer, conduct a search on one of our customer sites.
    
Contractor: Through Distributor
Vehicle Service & Heavy Equipment: Through Distributor
Industrial, Manufacturing & Processing: Through Distributor`
  },
  {
    title: 'Looking for Distributorship in your locality?',
    content: <a href="contact" id='acco_contact'>You can Contact Us</a>
  }
];