import React from "react";
import spray_tip from "../../assets/home_owner/spray_tip.png";
function RightTip() {
  return (
    <div>
      <div className="last_section">
      <img src={spray_tip} id='spray-tip-mobile' />
        <div className="blue-card">
          <h3 id="yellow_text">PAINT SPRAYER TIP SELECTOR</h3>
          <h2 id="blue-card-title">
            FIND THE RIGHT TIP FOR YOUR
            PROJECT
          </h2>
          <p id="blue-content">
            Choose the perfect spray tip for your specific project. Each
            designed and manufactured to Pradco’s high quality standards.
          </p>
          <a href='/spray_tip'>
          <button id="blue_btn" >View
          </button></a>
        </div>
        <img src={spray_tip} id='spray-tip-image' />
      </div>
    </div>
  );
}

export default RightTip;
