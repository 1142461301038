import React from 'react'
import { Header, Footer, HeaderPC } from '../components'
import Banner from '../components/PaintSprayers/banner'
import '../components/PaintSprayers/paintsprayers.css'
import AirlessPaintSprayer from '../components/PaintSprayers/airlessPs'
import Machines from '../components/PaintSprayers/machines'
import WhySpray from '../components/PaintSprayers/whySpray'

function PaintSprayers() {
  return (
    <div className='mobile_view'>
        <Header/>
        <HeaderPC/>
        <Banner/>
        <AirlessPaintSprayer/>
        <Machines/>
        <WhySpray/>
        <Footer/>
    </div>
  )
}

export default PaintSprayers