import React from "react";
import sgun from "../../assets/accessories/onepass.png";
import battery from "../../assets/accessories/battery.png";
import extensions from "../../assets/accessories/extensions.png";
import filters from "../../assets/accessories/filters.png";
import guns from "../../assets/accessories/guns.png";
import hoses from "../../assets/accessories/hoses.png";
import lacquer from "../../assets/accessories/lacquer.png";
import pump from "../../assets/accessories/pump.png";
import roller from "../../assets/accessories/roller.png";
import spray_tip from "../../assets/accessories/spray_tip.png";
import storage from "../../assets/accessories/storage.png";
import fluid from "../../assets/accessories/fluid.png";
import suction from "../../assets/accessories/suction.png";

function OnePass() {
  return (
    <div>
      <div id="pic-cont">
        <img src={sgun} alt="Work Smarter" />

        <div id="acontent-r">
          <h2 id='onepass_h2'>FAST, ONE-PASS COVERAGE ON ALL PROJECTS</h2>
          <p id="aimg-cont-p">
            The new Airless Spray Tip is here! The patented SoftSpray Technology
            provides greater control with a softer spray fan and less overspray
            to deliver professional quality results on every job, every time.
            Available in multiple sizes for spraying a wider range of surfaces
            and materials from stains to exterior paints.
          </p>
        </div>
      </div>

      <div id="black">
        <h1 id="black_title">BROWSE PAINT SPRAYER ACCESSORIES</h1>
      </div>

      <div className="list">
        {/* LIST STARTS HERE */}

        <div className="accessory">
          <img src={battery} alt="Battery" className="acc_pic" />
          <div className="acc_content">
            <h3>Batteries & Chargers</h3>
            <p>
              Top quality Lithium-ion batteries and chargers provide the power
              you need to keep spraying longer so you can finish your projects
              faster.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img src={extensions} alt="Battery" className="acc_pic" />
          <div className="acc_content">
            <h3>Extensions</h3>
            <p>
              Extend your reach and eliminate ladder hassles to safely spray
              hard-to-reach areas like high walls, vaulted ceilings, decks,
              railings and soffits with Pradco’s full line of extended reach
              accessories.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img src={filters} alt="Filters & Stainers" className="acc_pic" />
          <div className="acc_content">
            <h3>Filters & Strainers</h3>
            <p>
              The easiest way to maximise spray performance, extend the life of
              your equipment and get the professional quality finish you want is
              to use genuine Pradco filters and strainers in your sprayers and
              guns.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img src={guns} alt="Filters & Stainers" className="acc_pic" />
          <div className="acc_content">
            <h3>Guns</h3>
            <p>
              Pradco’s well-balanced and lightweight airless spray guns provide
              a comfortable spraying experience for all of your projects. The
              in-handle paint filter reduces tip clogs and improves the quality
              of your finish.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img src={hoses} alt="Filters & Stainers" className="acc_pic" />
          <div className="acc_content">
            <h3>Hoses</h3>
            <p>
              Available in 7.6 and 15 m (25 and 50 ft) lengths, Pradco DuraFlex
              airless hoses are durable, flexible and designed to ensure you get
              the best results from your airless sprayer. Always check your
              sprayer for the maximum hose length supported.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img src={lacquer} alt="Filters & Stainers" className="acc_pic" />
          <div className="acc_content">
            <h3>Lacquer Conversion Kits</h3>
            <p>
              These conversion kits allow you to spray all types of lacquers and
              flush with lacquer thinner when tackling projects with Pradco
              SprayX17 and SprayX19 cart models.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img src={pump} alt="Filters & Stainers" className="acc_pic" />
          <div className="acc_content">
            <h3>Pump Replacements & Parts</h3>
            <p>
              Easily replace your pump in a matter of minutes to avoid downtime
              and finish faster with Pradco’s fast and easy pump replacement
              systems. Available on Pro Series sprayers.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img
            src={roller}
            alt="Roller Systems & Covers"
            className="acc_pic"
          />
          <div className="acc_content">
            <h3>Roller Systems & Covers</h3>
            <p>
            Stop wasting time and dealing with the mess caused by dipping rollers in a paint tray. Pressure Rollers let you keep rolling to finish jobs faster and will ensure that you achieve a quality finish when paired with our top quality roller covers.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img
            src={spray_tip}
            alt="Spray Tips & Tip Guards"
            className="acc_pic"
          />
          <div className="acc_content">
            <h3>Spray Tips & Tip Guards</h3>
            <p>
            Choosing the right tip will determine the quality of your finish and help you save a significant amount of time and money. Pradco tips are easy to identify, hence you can use less material and finish your projects faster.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img src={storage} alt="Storage Cases" className="acc_pic" />
          <div className="acc_content">
            <h3>Storage Cases</h3>
            <p>
            Keep your sprayer in excellent condition and maximise your investment by always storing your sprayer in a genuine Pradco storage case. Each case designed specifically for each sprayer and can include extra accessories for quicker project starts.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img src={fluid} alt="Storage Fluid" className="acc_pic" />
          <div className="acc_content">
            <h3>Storage Fluid</h3>
            <p>
            Extend the life of your sprayer by using the right maintenance fluids. Pump Armour prevents corrosion and ensures easy start up when your sprayer is not in use to maximise your investment.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
        <hr className="partition" />

        <div className="accessory">
          <img src={suction} alt="Filters & Stainers" className="acc_pic" />
          <div className="acc_content">
            <h3>Suction Tube Sets</h3>
            <p>
            Replacement suction tube sets come in handy when using your sprayer often or if the existing tube set becomes damaged during a project.
            </p>
            <a className="acclearn_more" href="/product_catalogue">LEARN MORE</a>
            <hr className="acc_bline" />
          </div>
        </div>
       <br/><br/>
      </div>
    </div>
  );
}

export default OnePass;
