import React from "react";
import acc_banner from "../../assets/accessories/accessories_banner.png";
import a_banner_mobile from "../../assets/accessories/a_banner_mobile.png";


function Banner() {
  return (
    <div>
      <div id="banner_mobile_hid">
      <h1 id="abanner_h1">PAINT SPRAYER ACCESSORIES</h1>
      <p id="abanner_p">
        Time-saving paint sprayer tools help you get the most out of your Pradco
        sprayer. With extended reach tools, high volume rolling solutions,
        additional tips and more, you’ll be more efficient and complete any
        project in even less time by customising your sprayer with genuine
        Pradco paint sprayer accessories.
      </p></div>
      <img src={acc_banner} alt="Home Owner" id="banner_soln" />
      <img src={a_banner_mobile} alt="Home Owner" id="banner_soln_mobile" />

      <h1 id="title_1">FASTER AND EASIER PAINTING PROJECTS</h1>
      <p id="tag">
      We engineer and manufacture products and accessories to support a wide range of in-plant manufacturing materials and applications. 
      </p>
    </div>
  );
}

export default Banner;
