import React from "react";

function Description() {
  return (
    <div className="terms">
      <div className="parallel_j">
      <div className="quick_job_links_mob">
          <a href="#introduction">Mechanical Engineer</a><br/>
          <a href="#infowecollect"> Electrical Engineer</a><br/>
          <a href="#howweuse">Engineer - Product & Planning</a><br/>
          <a href="#children"> Design Engineer - Mechanical</a><br/>
          <a href="#revisions"> CNC Machinist</a><br/>
          <a href="#iot">Technical Information Specialist</a><br/>
          <a href="#transferofinformation"> Clean Room Assembler</a><br/>
          <a href="#links"> Channel Marketing Specialist</a><br/>
          <a href="#useraccess"> Business Development Specialist</a><br/>
          <a href="#security"> Digital marketing Specialist</a><br/>
          <a href="#termsandmodifications">
            Communications & Design Specialist
          </a><br/>
          <a href="#applicablelaw">Finance Specialist</a><br/>
          <a href="#grievanceredressal"> Quality Engineer</a><br/>
          <a href="#business_analyst">
Business Analyst - Logistics</a><br/>
          <a href="#sde"> Software Engineer</a><br/>
          <a href="#pcb"> PCB Design Engineer</a><br/>
          <a href="#sales_manager"> Sales Manager</a><br/>
          <a href="#territory_manager"> Territory Manager</a><br/><br/>

        </div>
        <div className="content-p">
          <h2 className="headings" id="introduction">
            Mechanical Engineer
          </h2>
          <h4>Essential Duties</h4>
          <p>
            <ul>
              <li>
                Perform Engineering tasks in the development of new products and
                cost reduction.
                <ul>
                  <li>
                 
                    Participation in development of Product Specification
                  </li>
                  <li>Produce conceptual designs</li>
                  <li>
                    Guide development process to produce practical designs
                  </li>
                  <li> Creation of detailed design drawings</li>
                  <li> Identification of applicable standards</li>
                  <li> Analysis of design to applicable standards</li>
                  <li>
                    {" "}
                    Analysis of design using engineering analysis tools and
                    techniques{" "}
                  </li>
                  <li> Review of instruction manuals and product literature</li>
                  <li>
                    {" "}
                    Define and lead prototype development and lab and field
                    tests
                  </li>
                  <li> Work effectively in a team environment</li>
                  <li> Work with manufacturing on process development</li>
                  <li> Conduct design reviews, safety reviews, etc.</li>
                </ul>
                <li>Leader of new product development projects as required.</li>
                <ul>
                  <li>
                    Complete project feasibility and Pre-Prod authorizations
                  </li>
                  <li>Create and maintain project schedules</li>
                  <li>Manage project to meet schedules, including:</li>
                  <ul>
                    <li>
                      Working with other team members to ensure satisfactory
                      completion of all steps in development process
                    </li>
                    <li>
                      Identification and communication of threats to schedule to
                      appropriate management
                    </li>
                  </ul>
                  <li>Participate in release for sale activities</li>
                  <li>
                    Provide periodic oral and written reports of project status
                  </li>
                </ul>
                <li>
                  Participate in identification of new product opportunities for
                  Pradcoby:
                </li>
                <ul>
                  <li>Recognizing customer product needs</li>
                  <li>Evaluating Pradco and competitive products</li>
                  <li>
                    Proposing and developing proper technology to fulfill
                    customer needs
                  </li>
                </ul>
                <li>
                  Direct and perform standard product support activities such
                  as:
                </li>
                <ul>
                  <li>
                    Analyzing and writing Engineering Change Orders for products
                  </li>
                  <li>
                    Troubleshooting product problems in manufacturing, lab, or
                    customer sites
                  </li>
                  <li>Initiating corrective action activities</li>
                </ul>
                <li>
                  Learn, participate in development, and apply knowledge of:
                </li>
                <ul>
                  <li>
                    Stay abreast of developments in technical field to maintain
                    technical knowledge
                  </li>
                  <li>Pradcoproduct line</li>
                  <li>Operational systems</li>
                  <li>Pradcoand industry design standards</li>
                  <li>PradcoQuality Management System</li>
                  <li>Pradcocustomer applications and requirements</li>
                  <li>Emerging trends in our customers' markets</li>
                </ul>
                <li>Other duties as assigned</li>
              </li>
            </ul>
            <br />
            <h4>Position Requirements</h4>
            <ul>
              <li>
                Bachelors/Masters degree in engineering from an accredited
                institution or equivalent as approved by divisional engineering
                management group
              </li>
              <li>Excellent oral and written communication skills</li>
              <li>Excellent engineering/technical aptitude</li>
              <li>
                Minimum of 3 years experience in new product development with
                demonstrated ability to independently produce innovative and
                successful designs as part of large projects or for entire small
                projects
              </li>
              <li>Developing as a technical expert in technical topic(s)</li>
              <li>
                Ability to recognize customer product needs and trends in the
                marketplace that affect product requirements
              </li>
            </ul>
          </p>
          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 className="headings" id="infowecollect">
            Electrical Engineer
          </h2>
          <h4>Responsibilities</h4>
          <ul>
            <li>Apply your engineering skills to solve complex challenges</li>
            <li>Write embedded software in C/C++</li>
            <li>Design circuit boards in Altium Designer</li>
            <li>Establish product requirements and performance targets</li>
            <li>Create and execute product qualification plans</li>
            <li>
              Interact daily on-site with a team, including lab work, project
              meetings, and design discussions
            </li>
            <li>
              Provide A+ customer experience to both external and internal
              customers
            </li>
          </ul>

          <h4>Qualifications</h4>
          <ul>
            <li>
              Bachelor's degree in Electrical Engineering, Computer Engineering,
              or Computer Science
            </li>
            <li>Problem-solving, technical, math, and science skills</li>
            <li>Effective written and verbal communication</li>
            <li>
              Positive attitude, ability to work in a team, interest in
              leadership, self-motivated
            </li>
            <li>Highly innovative and tinkering mindset</li>
            <li>Experience desired but not required</li>
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 className="headings" id="howweuse">
          Engineer - Product & Planning
          </h2>
          <h4>Essential Duties</h4>
          <ul>
            <li>
              Forecasting
              <ul>
                <li>
                  Ensure forecasting system is maintained and daily, weekly, and
                  monthly maintenance is performed and exceptions are analyzed. 
                  Forecasts adjusted as required and driving the correct
                  requirements to meet demand.
                </li>
                <li>
                  Ensure GAINS system is maintained and that all exceptions are
                  analyzed.  Perform supply and demand analysis and incorporate
                  it into the forecast.  Generate reports and make adjustments
                  if necessary.
                </li>
                <li>
                  Communicate with marketing to refine and improve data to
                  accurately forecast product
                </li>
              </ul>
              <li>
                Planning
                <ul>
                  <li>
                    Plan all purchased and manufactured parts utilizing the best
                    ordering approach.  EOQ, min/max, fixed days, Kanban, MRP,
                    etc.
                  </li>
                  <li>
                    Ensure accurate planning parameters are set in planning
                    systems - GAINS, Oracle.
                  </li>
                  <li>
                    Schedule manufacturing orders and ensure customer
                    requirements are met.
                  </li>
                  <li>
                    Give work direction and prioritize schedules on the shop
                    floor.
                  </li>
                  <li>
                    Communicate with customer service to align priorities to
                    meet customer requirements.
                  </li>
                  <li>
                    Keep management proactively informed of manufacturing
                    challenges and issues (capacity, machine downtime, and
                    fluctuations in resources).
                  </li>
                </ul>
              </li>
              <li>
                {" "}
                Purchasing
                <ul>
                  <li>
                    Plan and execute the flow of externally procured material
                    from the supplier into the factory for assigned products
                    ensuring inventory optimization and flow of material.{" "}
                  </li>
                  <li>
                    Ensure planning information is communicated with suppliers
                    in a timely manner.  Phase-out, delivery changes, etc.{" "}
                  </li>
                  <li>
                    Proactively communicate supply shortages to appropriate
                    personnel.{" "}
                  </li>
                  <li>
                    Follow up with suppliers as necessary, to resolve delivery
                    problems, cost changes, or quality issues.
                  </li>
                </ul>
              </li>
            </li>
            <li>
              Inventory
              <ul>
                <li>
                  Manage inventory to ensure cell objectives are met and in line
                  with company objectives.
                </li>
                <li>
                  Manage surplus and obsolete inventory and actively disposition
                  inventory if required.
                </li>
                <li>
                  Manage assigned product to meet customer service and inventory
                  plans.
                </li>
              </ul>
            </li>
            <li>
              Reporting
              <ul>
                <li>Report on areas such as inventory and service levels.</li>
              </ul>
            </li>
            <li>Perform other duties as assigned</li>
          </ul>

          <h4>Position Requirements</h4>
          <ul>
            <li>
              Education/Experience
              <ul>
                <li>
                  Bachelor's degree in Operations Management, Logistics and
                  Supply Chain Management, or related field is preferred or 4
                  years of proven planning experience working in manufacturing
                  and/or distribution environment.
                </li>
                <li>
                  Registered and working towards APICS CPIM certification if
                  Bachelor’s degree is not in Logistics and Supply Chain
                  Planning field 
                </li>
                <li>
                  Plan in place to enroll in Operations Management, Logistics
                  and Supply Chain Management, or related field and willing to
                  complete Bachelor’s degree  
                </li>
              </ul>
              <li>
                Required Skills/Knowledge/Abilities
                <ul>
                  <li>MRP/ERP system knowledge</li>
                  <li>Competent PC skills.</li>
                  <li>
                    Knowledge of Supply Chain and lean manufacturing concepts,
                    practices, and procedures.
                  </li>
                  <li>Good written and verbal communication skills</li>
                </ul>
              </li>
            </li>
          </ul>
          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 className="headings" id="children">
            Design Engineer - Mechanical
          </h2>
          <h4>Essential Duties</h4>
          <ul>
            <li>
              CAD design & documentation
              <ul>
                <li>
                  Provide CAD layout and assembly designs for new products in
                  cooperation with a design team consisting of engineers, other
                  designers and lab technicians.
                </li>
                <li>
                  Produce CAD conceptual designs layout and detail drawings of
                  new products.
                </li>
                <li>
                  Revise and redraw, as required, production drawings of
                  existing standard products.
                </li>
                <li>
                  Use and apply Pradcodesign and drafting standards, including
                  geometric tolerancing.
                </li>
                <li>Complete ECOs, by utilizing PDM and other tools.</li>
                <li>Perform tolerance analysis on critical assemblies.</li>
                <li>Do basic engineering design calculations.</li>
              </ul>
            </li>
            <li>
              Development project activities
              <ul>
                <li>
                  Work with Model Shop, Purchasing, Technicians, and
                  Manufacturing as required to produce designed products.
                </li>
                <li>
                  Communicate with manufacturing and suppliers to develop cost
                  effective parts designs.
                </li>
                <li>
                  Release drawings to manufacturing and facilitate the
                  manufacturing progress throughout the pre-prod cycle.
                </li>
                <li>
                  Identify design activities and solve problems. Perform
                  engineering calculations as required to provide sound designs.
                </li>
              </ul>
            </li>
            <li>Project management & leadership</li>
            <li>Assist lab technicians in completion of test plans.</li>
            <li>Other duties as assigned.</li>
          </ul>
          <h4>Position Requirements</h4>
          <ul>
            <li>Two-year technical degree or equivalent.</li>
            <li>Consistent Highly Effective performance </li>
            <li>Thorough knowledge of mechanical design drafting practices.</li>
            <li>
              Capable of using and applying Pradcodesign and drafting standards.
            </li>
            <li>Minimum 1 year of experience.</li>
            <li>
              Demonstrated ability to finish conceptual design, including
              application of geometric tolerance, evaluates fits.
            </li>
            <li>
              Basic knowledge of relevant manufacturing processes with some
              experience on tooled parts.
            </li>
            <li>
              Thorough knowledge of use and application of geometric tolerancing
              and tolerance analysis.
            </li>
          </ul>
          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 className="headings" id="revisions">
            CNC Machinist
          </h2>
          <h4>Essential Duties</h4>
          <ul>
            <li>Operate a variety of CNC machines within a factory</li>
            <li>Maintain a clean, safe work area.</li>
            <li>Adjusts tools and replace as needed</li>
            <li>
              Perform daily maintenance of machines as required and keep
              supervisor informed of necessary maintenance beyond operator
              ability
            </li>
            <li>
              Must read and understand layouts, routings, and blueprints for the
              parts being run
            </li>
            <li>
              Demonstrated proficiency with Geometric Dimensioning and
              Tolerancing
            </li>
            <li>
              Provide accurate inventory counts at all times and keep parts in
              appropriate storage areas
            </li>
            <li>
              Proficient in the use of comparators, CMM, Trimos, gages, and
              related measurement tools
            </li>
            <li>
              Collect and input SPC data which involves the use of comparator,
              CMM, Trimos, gages, and related test instruments
            </li>
            <li>
              Perform necessary side operations during machine cycle time as
              required (i.e., deburring).
            </li>
            <li>
              Work as a team member to aid all shifts and support personnel to
              produce machined parts which meet Pradcostandards of quality and
              productivity goals
            </li>
            <li>Other duties as assigned.</li>
          </ul>

          <h4>Position Requirements</h4>
          <u>Education / Certifications</u>
          <ul>
            <li>Diploma or Equivalent course</li>
            <li>
              Completion of a Machine Tool Technology degree program or an
              equivalent number of years of machining experience is preferred.
            </li>
            <li>
              Candidates that have completed the first year of a 2-year Machine
              Tool Technology program may be considered for employment
              contingent on the successful completion of the degree,
              certificate, or program.
            </li>
          </ul>
          <i>
            Note: A completed 2-year degree in machining or machine technology
            is required to be promoted to the next level (transcript is
            required) along with required time in grade and completed
            promotional checklist. 
          </i>
          <br />
          <br />

          <u>Skills/Abilities/Competencies</u>
          <ul>
            <li>
              Ability to read and understand CNC programs and operational
              layouts
            </li>
            <li>
              Ability to perform a sequence of operations under minimum
              supervision and consistently maintain the performance levels set
              for quality, productivity, and efficiency.
            </li>
            <li>
              Full compliance with safety rules and regulations to prevent
              unsafe set-ups, operations, or acts which might cause  injury  to
              self, others, or the environment
            </li>
            <li>Good interpersonal,  written, and oral communication skills</li>
            <li>Ability to plan effectively and execute the plans</li>
            <li>Strong mechanical aptitude</li>
            <li>Strong problem solving and troubleshooting skills</li>
            <li>
              Must have strong PC skills and the ability to utilize all Pradco’s
              Manufacturing ERP systems and other manufacturing operating
              systems.
            </li>
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 className="headings" id="iot">
            Technical Information Specialist
          </h2>
          <h4>Essential Duties</h4>

          <ul>
            <li>
              Develop product knowledge in all product lines in order to provide
              technical support to all field personnel on the servicing of
              Pradco products.
            </li>
            <li>
              Establish sound troubleshooting and repair knowledge on all
              assigned equipment. Be prepared to provide trouble shooting and
              repair assistance by telephone to Pradcoend users, distributors
              and Pradco field personnel.
            </li>
            <li>Provide limited application assistance on equipment</li>
            <li>
              Monitor and report any product defects to the Product Service
              Managers, Quality and Engineering departments as soon possible.
            </li>
            <li>
              Remain the focal point for communication to the field and other
              pertinent Pradcopersonnel on the product problems, actions, and
              resolution.
            </li>
            <li>
              Each specialist will have the responsibility for knowing and
              following all ISO procedures related to their jobs.
            </li>
            <li>
              Construct a complete reference library that will enable you to
              provide fast and accurate information to the customer.
            </li>
            <li>
              Be prepared to assist in product modifications and system
              requirements with customers, Product Managers, Marketing, Product
              Development, and Engineering groups
            </li>
            <li>
              Establish sound product and system troubleshooting skills on all
              Pradcoequipment new and old.
            </li>
            <li>
              Attend information gathering and dissemination meetings to support
              and assist in resolving product problems with other support
              groups.
            </li>
            <li>
              Attend new and old product training seminars to increase product
              troubleshooting skills, application knowledge, and product
              awareness to maintain technical expertise.
            </li>
            <li>
              Conduct product-training classes to the work group covering both
              the technical and application aspects of the product.
            </li>
            <li>Handle Customer Service inquiries and duties as required.</li>
            <li>
              Provide reliable decision making information at the time a
              decision is required (develop and maintain a sound and practical
              sense of urgency).
            </li>
            <li>
              Establish and maintain a professional, responsive working
              relationship and rapport with all Pradco distributors and all
              other departments within Pradco.
            </li>
            <li>
              Travel to the field as required, to establish reliable service
              feedback, perform technical training and technical product support
              as required. Some weekend travel and work will be required.
            </li>
            <li>Other duties as assigned.</li>
          </ul>

          <h4>Position Requirements</h4>
          <u>Education/Certifications</u>
          <ul>
            <li>
              Three year Technical Degree or equivalent experience which will be
              evaluated by the hiring supervisor.
            </li>
            <li>
              Two years of Pradcoproduct knowledge experience or equivalent
              experience in a similar field.
            </li>
            <li>
              Product development (Lab), product service, sales, or related
              Pradco’s products knowledge is a plus
            </li>
            <li>
              Excellent communication, presentation, and interpersonal skills.
            </li>
            <li>Strong PC and data entry skills.</li>
            <li>Willingness to travel 15% of the time or as required.</li>
          </ul>
          <h4>Desirable Qualifications</h4>
          <ul>
            <li>
              Demonstrate ability to communicate effectively with all
              organizational levels.
            </li>
            <li>Training experience.</li>
            <li>Electronics</li>
            <li>Service department experience.</li>
            <li>Customer service experience</li>
            <li>Quality background.</li>
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 id="transferofinformation" className="headings">
            Clean Room Assembler
          </h2>
          <h4>JOB PURPOSE</h4>
          <p>
            Production/completion of final and sub-assemblies for
            internal/external customers on a timely basis maximizing quality and
            efficiency. In addition, the B assembler will train co-workers, and
            maintain inventory accuracy.
          </p>
          <h4>ESSENTIAL DUTIES</h4>
          <ul>
            <li>
              Assemble, set-up, test, and pack both sub-assemblies and completed
              assemblies with a minimum of supervision.
            </li>
            <li>
              Maintain required work efficiency and productivity on assigned
              work station/assembly position.
            </li>
            <li>
              Monitor daily quality of assemblies and communicate any
              discrepancies.
            </li>
            <li>
              Participate in QAT and problem solving to continually improve the
              quality of assemblies and processes.
            </li>
            <li>Report information on quality, delivery and cost.</li>
            <li>Other duties as assigned.</li>
          </ul>

          <h4>POSITION REQUIREMENTS</h4>
          <u>Essential Qualifications:</u>
          <ul>
            <li>High School diploma or equivalent.</li>
            <li>
              Requires a minimum of 66 months of White Knight assembly
              experience to gain the flexibility to perform efficiently on all
              assembly lines, including some testing.
            </li>
            <li>
              Ability to efficiently perform basic assembly from detailed
              instructions.
            </li>
            <li>
              Requires the ability to add and subtract decimals or fractions and
              work from operation layouts and written instructions.
            </li>
            <li>
              Ability to timely and accurately complete assembly/test logs.
            </li>
            <li>
              Requires the ability to perform standard operations from detailed
              instructions.
            </li>
            <li>Ability to train co-workers on standard operations.</li>
            <li>Ability to maintain inventory accuracy</li>
            <li>Ability to work under pressure to meet deadlines</li>
            <li>Ability to trouble-shoot pump performance issues</li>
            <li>
              Excellent Manual Dexterity for handling and assembling small parts
            </li>
            <li>
              Ability to perform all job requirements of a Class C Assembler
            </li>
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 className="headings">Channel Marketing Specialist</h2>
          <h4>Essential Duties:</h4>
          <ul>
            <li>
              Own new account set up and existing account maintenance within
              Pradcosystems.
            </li>
            <li>
              Manage GDAP( PradcoDistributor Alliance Program) & Distributor
              agreements
            </li>
            <li>
              Manage pricing contracts, price files and SPR’s (Special Price
              Requests)
            </li>
            <li>
              Support development and ongoing maintenance of Partner Portal and
              SIS
            </li>
            <li>
              Execute industry trade show plan working with outside vendors 
            </li>
            <li>Track trade show budget and analysis of trade show metrics</li>
            <li>
              Assist in the development and implementation of marketing programs
              and promotions 
            </li>
            <li>
              Track performance of programs and promotions, making
              recommendations Assist in development and implementation of
              marketing and sales tools.{" "}
            </li>
            <li>Support product launches as needed</li>
            <li> Manage Hoover’s account and reporting</li>
            <li>Assist with other marketing activities</li>
            <br />
          </ul>
          <u>Position Requirements:</u>
          <ul>
            <li>
              Essential
              <ul>
                <li>
                  Bachelor's degree in Marketing, Business Administration or
                  related field or equivalent work experience. 
                </li>
              </ul>
            </li>
            <li>
              Experience
              <ul>
                <li>
                  2 - 5 years successful experience as administrative assistant,
                  marketing coordinator , distributor development or similar
                  role; open to recent graduates with 0-2 years’ experience  
                </li>
              </ul>
            </li>
            <li>
              Skills/Abilities/Competencies
              <ul>
                <li>
                  Experience working with MS Office software (Word, Excel and
                  PowerPoint)
                </li>
                <li>Reporting experience using multiple systems of record</li>
                <li>Strong interpersonal and communications skills</li>
                <li>
                  Excellent attention to detail and diligent follow-through
                </li>
                <li>
                  Demonstrates a basic knowledge of professional principles and
                  skills.
                </li>
                <li>Fluent in Spanish or Portuguese a plus</li>
                <li>Nominal travel</li>
              </ul>
            </li>
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 className="headings" id="useraccess">
            Business Development Specialist
          </h2>

          <h4>Requirements</h4>
          <p>
            Experience: Minimum of 5 years B2B sales experience in an heavy
            equipment, automotive or industrial environment (lubricants, garage
            equipment, hydraulics, industrial B2B, e.g.) Education: Commercial
            or Technical Bachelor or Master degree Skills:
            <ul>
              <li>Fluent in English and German</li>
              <li>PC literate : Word, Excel, Powerpoint</li>
              <li>Excellent verbal and written communication skills</li>
            </ul>
            Traveling minimal 60 % of the time (staying overnight in hotels)
            should not be an obstacle for you.
          </p>
          <h4>Characteristics</h4>
          <p>
            Having a hunter profile with a commercial attitude, you are not only
            able to find new customers as well as new channels, but you can also
            develop new and existing markets.  Managing the distribution is not
            new to you.  You use your excellent communication skills to build
            relationships with the customers as well as with your colleagues. 
            You are a team-player who enjoys collaborating with others, but also
            have the ability to administer self-discipline and self-management. 
            Being a structured and organized person, you know how to prioritize
            and how to optimize your travel time.
          </p>
          <h4>Responsibilities</h4>
          <ul>
            <li>
              Develop relationships and business with key partners, such as
              automotive dealerships, oil and grease manufacturers, mining and
              construction, industrial processing plants, engineering companies
              etc.
            </li>
            <li>
              Improve market share by focusing on sales development of
              PradcoLubrication products at key markets{" "}
            </li>
            <li>
              Support and Develop new and existing distribution network by
              making and effective follow-up on territory and individual
              business plans
            </li>
            <li>
              Gather local product requirements and translate them into product
              specifications for marketing
            </li>
            <li>
              Ensure excellent customer product and application knowledge by
              providing adequate training
            </li>
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 id="security" className="headings">
            Digital marketing Specialist
          </h2>
          <h4>Reqiurements : </h4>
          <ul>
            <li>
              Master degree in Marketing / Digital Marketing / Business
              Economics
            </li>
            <li>
              Combines data with business savviness – is able to approach data
              or system functionality from a business priority perspective
            </li>
            <li>Customer-centric attitude with constant focus on usability</li>
            <li>
              Learns fast, works fast, takes initiative, organized, meticulous,
              asks questions when needed
            </li>
            <li>
              Takes project ownership, looks to improve processes, manages
              stress well, able to work in a team
            </li>
          </ul>

          <h4>Responsibilities : </h4>
          <ul>
            <li>
              Support the Marketing team with the implementation of Web related
              projects through product data entry & content maintenance in a
              “Product Information Management” system (PIM) and other content
              implementation platforms (CMS)
            </li>
            <li>
              Follow-up on re-occurring data jobs to ensure ongoing business
              (processes)
            </li>
            <li>
              Ensure that leads are validated with key information to enable
              better sales follow-up
            </li>
            <li>
              Carry out data exercises to ensure and maintain qualitative
              customer and channel data (CRM)
            </li>
            <li>
              Look for opportunities to automate/optimize current digital
              marketing processes to maximize the outcome as well as the overall
              usability
            </li>
            <li>Provide project analytics & reports.</li>
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 id="termsandmodifications" className="headings">
            Communications & Design Specialist
          </h2>
          <h4>Job Purpose</h4>
          <p>
            Work as part of the Corporate Communications team to support
            internal and external communications projects and customer
            engagement planning and execution. The Senior Communications &
            Design Specialist will design and write content for Pradco’s
            internal communications channels and support external communications
            strategies through corporate social media channels.
          </p>

          <h4>Essential Duties</h4>
          <ul>
            <li>
              Develop and execute employee relations story ideas on a variety of
              topics including personal employee experiences, community
              relations, customer service, benefits, Pradco’s core values, and
              more
            </li>
            <li>
              Collaborate and conduct interviews with employees in a variety of
              functions worldwide to research and prepare internal
              communications content
            </li>
            <li>
              Write and design motivating and informative announcements for the
              company's various internal communications channels, including the
              Intranet, email, and video monitor system
            </li>
            <li>
              Craft content for corporate social media channels and monitor
              competitor and peer social media
            </li>
            <li>
              Find customers talking about Pradcoand its brands on social media,
              then address issues by channeling them into proper departments for
              responses, supporting Pradco’s A+ Service initiative
            </li>
          </ul>

          <h4>Position Requirements</h4>
          <u>Education/Certifications</u>
          <ul>
            <li>Bachelor’s degree required.</li>
            <li>
              2-5 years of experience in (internal and external) business,
              nonprofit, or public sector communications in both graphic design
              and writing roles
            </li>
          </ul>

          <u>Skills/Abilities/Competencies</u>
          <ul>
            <li>
              Strong writing and editing skills, with the ability to write about
              a wide range of topics
            </li>
            <li>
              Fluent in Adobe Illustrator, Photoshop, and InDesign with a strong
              design portfolio
            </li>
            <li>
              Experience with print and web production and vendor management
            </li>
            <li>
              Excellent interpersonal, verbal, and written communication skills
              with a strong understanding of messaging and reputation management
            </li>
            <li>
              Able to organize and prioritize work assignments while handling
              multiple tasks
            </li>
            <li>
              Detail-oriented and able to work well within a deadline-driven
              environment
            </li>
            <li>
              Must be able to work effectively with diverse groups and
              individuals
            </li>
            <li>
              Experience working with social media, Figma, Adobe Experience
              Manager, HTML coding, and AP Style a plus
            </li>
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 className="headings" id="applicablelaw">
            Finance Specialist
          </h2>
          <u>ESSENTIAL DUTIES</u>
          <ul>
            <li>
              Coordinate and prepare the consolidation of financial statements
              for internal and external purposes, including all necessary
              adjusting and eliminating entries
            </li>
            <li>
              Analyze worldwide financial data sent to Corporate from Pradco
              subsidiaries and prepare consolidated interpretations for
              executive management
            </li>
            <li>Prepare financial statements and reports</li>
            <li>
              Coordinate reprojections and annual plan, consolidate and prepare
              financial statements and management reports
            </li>
            <li>
              Compliance with the internal control requirements of SOX as they
              relate to reporting
            </li>
            <li>
              Prepare divisional and regional financial statements and schedules
            </li>
            <li>
              Supports the global finance organization in the enhancement of the
              accounting and reporting processes including driving
              standardization, automation and process improvement in accounting
              practices across the organization, as well as the development of
              insightful monthly reporting
            </li>
            <li>Adhoc financial analysis and reporting</li>
            <li>
              Supports merger and acquisition activities through management of
              financial reporting integration responsibilities, as necessary.
            </li>
            <li>
              Assist in Controller Group site visits, to assess internal
              controls and reporting/accounting at the local level
            </li>
            <li>Supports the internal and external audit process.</li>
            <li>
              Assist the controller’s organization with special projects, as
              applicable.
            </li>
            <li>Supervise and develop staff, as applicable</li>
          </ul>

          <h4>POSITION REQUIREMENTS</h4>
          <u>Education/Certifications:</u>
          <ul>
            <li>Bachelor’s degree in accounting, finance or equivalent</li>
            <li>
              Certified Public Accountant (CPA) designation, active or inactive,
              is a plus
            </li>
          </ul>

          <u>Experience</u>
          <ul>
            <li>
              4-6 years relevant accounting experience, with at least two years
              working in private industry with publicly traded company
              experience
            </li>
          </ul>

          <u>Skills/Abilities/Competencies:</u>
          <ul>
            <li>
              In-depth and hands-on consolidations and international accounting
              experience in medium to large organizations
            </li>
            <li>OneStream/HFM experience preferred, but not required</li>
            <li>Intermediate to advanced Excel skills</li>
            <li>Familiar with generally accepted accounting principles</li>
            <li>
              Understanding of reporting requirements with exposure to foreign
              exchange translation
            </li>
            <li>
              Ability to work in a multi-tasked, fast-paced environment with
              tight deadlines
            </li>
            <li>Organized and efficient with good analytical skills</li>
            <li>Good communication skills, written and verbal</li>
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 id="grievanceredressal" className="headings">
            Quality Engineer
          </h2>
          <h4>Essential Duties</h4>
          <ul>
            <li>
              Work with suppliers to improve quality performance, implement
              process controls and quality plans.
            </li>
            <li>
              Responsible for all non-conforming material report (NMR)s for
              assigned area(s).
            </li>
            <li>
              Create and review supplier corrective action requests (SCARs) for
              effectiveness.
            </li>
            <li>
              Provide assistance to commodity managers with Supplier quarterly
              reviews (SQR).
            </li>
            <li>
              Drive proactive quality plans on New Product Development teams
              using APQP methodologies.
            </li>
            <li>
              Lead supplier audits for conformance to Pradco and industry
              policies and procedures including ATEX.
            </li>
            <li>
              Analyze supplier capabilities and recommend improvements to
              supplier.
            </li>
            <li>Attend warranty meetings representing supplier quality.</li>
            <li>
              Provide training and assistance to suppliers and purchasing
              personnel on Pradco supplier quality requirements including print
              and Pradco/Industry standard interpretation.
            </li>
            <li>
              Adhere to and recommend Improvements for the supplier quality
              related procedures and work instructions.
            </li>
            <li>
              Analyze Supplier Scrap and Rework for trends and ensure proper
              corrective action and cost recovery is taken.
            </li>
            <li>
              Provide assistance to product service regarding warranty issues
              and customer complaints (CC) for purchased product.
            </li>
            <li>
              Provide quality improvement suggestions to Pradco Design
              Engineering and Manufacturing groups.
            </li>
            <li>
              Provide general quality troubleshooting for day-to-day issues.
            </li>
            <li>Perform other duties as assigned.</li>
          </ul>

          <h4>Position Requirements</h4>
          <u>Education/Experience</u>
          <ul>
            <li>
              Bachelor’s degree in Engineering or Operations/Supply Chain
              Management.
            </li>
            <li>
              3-5 years Quality experience in a manufacturing environment with
              demonstrated ability to produce results.
            </li>
          </ul>

          <u>Skills/Abilities/Competencies</u>
          <ul>
            <li>
              Proficient in quality tools; i.e. assessments, First Article,
              FMEA, Problem solving, Root-Cause Analysis, Corrective Action,
              Mistake Proofing and Process Control.
            </li>
            <li>Possess excellent oral and written communication skills.</li>
            <li>
              Proficient in engineering drawing interpretation, GD&T, SPC, CMM
              Measuring Equipment and gauging.
            </li>
            <li>Good mechanical aptitude.</li>
            <li>Six Sigma Green Belt Certified.</li>
          </ul>
          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 id="business_analyst" className="headings">
            Business Analyst - Logistics
          </h2>
          <h4>Essential Duties</h4>
          <ul>
            <li>
              Drive development and implementation of system-related business
              process enhancements
            </li>
            <li>
              Support implementation of the Oracle/ERP solutions to additional
              locations across the organization
            </li>
            <li>
              Collaborate with the business to gather requirements and provide
              application design, configuration, module set-up, and production
              support
            </li>
            <li>
              Support and enhance the Oracle EBS modules, reporting, interfaces,
              and other related applications
            </li>
            <li>
              Assist in the development, management, and execution of project
              plans
            </li>
            <li>
              Perform Oracle EBS configurations and functional module set-ups
              utilizing Standard Business Solutions
            </li>
            <li>
              Identity, document, and communicate significant system shortfalls
              (Gaps)
            </li>
            <li>
              Contribute to the ongoing improvement of our Oracle/ERP systems
            </li>
            <li>
              Develop and execute test scripts to verify controls, system
              integrity, and usability
            </li>
            <li>
              Assist in the development of user documentation and conduct user
              training
            </li>
            <li>Work with Oracle Support to resolve issues</li>
            <li>Travel to other locations as necessary (up to 20% annually)</li>
          </ul>

          <h4>Position Requirements</h4>
          <u>Education/Experience</u>
          <ul>
            <li>
              College degree in Business or MIS or related field (or equivalent)
            </li>
            <li>
              5 - 7 years of experience in a Manufacturing and/or distribution
              environment
            </li>
          </ul>

          <u>Skills/Abilities/Competencies</u>
          <ul>
            <li>
              Experience utilizing Oracle EBS modules within a manufacturing or
              distribution environment
            </li>
            <li>Strong functional knowledge of Oracle EBS R12</li>
            <li>Excellent analytical and problem-solving skills</li>
            <li>
              Strong organizational skills and the ability to adhere to
              deadlines with reliability and accuracy
            </li>
            <li>
              The ability to communicate and work effectively with business
              users, managers, peers, and project teams
            </li>
            <li>Knowledge of Oracle WMS/Shipping preferred</li>
          </ul>
          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 id="sde" className="headings">
            Software Engineer
          </h2>
          <h4>Business Purpose</h4>
          <p>
            Responsible for the development of technical specifications, design,
            code, testing, implementation, and ongoing maintenance of websites
            and hybrid/mobile applications. This position is responsible for
            creating and customizing websites, web components, workflows,
            hybrid/mobile applications, web services, and SQL/NoSQL database
            procedures. This position is also responsible for developing and
            customizing interfaces for web applications. The individual will
            provide business analysis, have superior technical skills, and have
            the ability to work as part of a team in a dynamic environment. They
            should have expert knowledge of web standards, application
            architecture, and UI/UX design patterns for web and mobile.
          </p>
          <h4>Essential Duties</h4>
          <ul>
            <li>
              Develop and/or customize websites and web applications to meet
              functional business requirements.
            </li>
            <li>
              Develop and/or customize workflows to meet company business
              processes.
            </li>
            <li>Design, develop, and test web services and APIs.</li>
            <li>
              Design, develop, and test data conversion programs and processes
              to ensure accurate data migrations.
            </li>
            <li>
              Work with end users and functional analysts to capture and
              document business requirements.
            </li>
            <li>Write and maintain technical specifications.</li>
            <li>
              Work with end users and functional analysts to perform unit,
              system, and regression testing.
            </li>
            <li>Migrate code changes to production environment.</li>
            <li>
              Develop documentation to detail the development, logic and testing
              processes.
            </li>
            <li>
              Provide technical expertise necessary to support the daily
              operations of the web applications.
            </li>
            <li>Provide after-hours on-call support as needed.</li>
            <li>Other duties as assigned.</li>
          </ul>

          <h4>Position Requirements</h4>
          <u>Education/Experience</u>
          <ul>
            <li>
              Bachelor’s degree in Computer Science, MIS, or related field
              desired.
            </li>
          </ul>
          <u>Experience</u>
          <ul>
            <li>
              Minimum of 5 years total experience developing and maintaining
              websites and hybrid/mobile applications.
            </li>
          </ul>

          <u>Skills/Abilities/Competencies</u>
          <ul>
            <li>
              Proficiency in HTML5, CSS3, Javascript ES6, REST, XML, JSON,
              ASP.Net, etc.
            </li>
            <li>
              Understanding of MVC architecture and underlying data structures
              and relationships.
            </li>
            <li>
              Experience with JS frameworks, such as Angular, React/NextJS, or
              Vue/NextJS
            </li>
            <li>
              Experience with Ionic/Capacitor, Flutter, or XCode and Android
              Studio
            </li>
            <li>
              Knowledge of CSS frameworks such Bootstrap, Material UI, Tailwind,
              etc.
            </li>
            <li>
              Knowledge of JS libraries ChartJS or D3js, jQuery, and WebGL
              preferred
            </li>
            <li>
              Ability to work with vendor support (i.e. open and manage
              incidents).
            </li>
            <li>Ability to create, maintain, and execute test plans.</li>
            <li>
              Strong analytical and problem-solving skills with an attention to
              detail.
            </li>
            <li>
              Strong team player with the interpersonal skills to work
              effectively with end-users and co-workers.
            </li>
            <li>Schedule/deadline-oriented.</li>
            <li>
              Excellent English language verbal and written communication
              skills.
            </li>
            <li>Highly motivated, self-starter desired</li>
            <li>
              Real-world web and hybrid/mobile application knowledge desired
            </li>
            <li>Ability to create development standards desired</li>
            <li>Unix shell scripting desired</li>
            <li>
              Experience with WordPress, Magento, and/or Salesforce a plus
            </li>
            <li>
              Experience with Java, J2EE, JSP, Oracle EBS, and Spring a plus
            </li>
          </ul>
          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 id="pcb" className="headings">
            PCB Design Engineer
          </h2>

          <h4>Essential Duties</h4>
          <ul>
            <li>
              Perform electrical hardware engineering tasks in the development
              of new products and cost reduction:
              <ul>
                <li>
                  Provide conceptual designs of new products in cooperation with
                  a design team consisting of engineers, designers, and lab
                  technicians.
                </li>
                <li>
                  Work with other engineers, model shop, purchasing, lab
                  technicians, and manufacturing as required to produce designed
                  products.
                </li>
                <li>
                  Produce CAD conceptual design layout and detail drawings of
                  new products.
                </li>
                <li>
                  Create schematic symbols and footprints to support new designs
                </li>
                <li>
                  Perform engineering calculations as required to provide sound
                  designs.
                </li>
              </ul>
              <li>
                Direct and perform standard product support activities such as:
                <ul>
                  <li>
                    Analyzing and writing Engineering Change Orders for products
                  </li>
                  <li>
                    Revise and redraw, as required, production drawings of
                    existing standard products. These drawings may be manual or
                    CAD.
                  </li>
                  <li>Evaluate and qualify replacement parts and circuits</li>
                  <li>Maintenance of electrical parts libraries</li>
                </ul>
              </li>
              <li>
                Learn, participate in development, and apply knowledge of:
                <ul>
                  <li>
                    Developments in technical field by staying abreast of
                    developments to maintain technical knowledge
                  </li>
                  <li>Pradco product line</li>
                  <li>Operational systems</li>
                  <li>Pradco and industry design standards</li>
                  <li>Pradco Quality Management System</li>
                  <li>Pradco customer applications and requirements</li>
                  <li>Emerging trends in our customers' markets</li>
                </ul>
              </li>
              <li>Other duties as assigned.</li>
            </li>
          </ul>

          <h4>Position Requirements</h4>
          <u>Education/Experience</u>
          <ul>
            <li>
              Bachelor’s degree in electrical, computer or software engineering
              or computer science from an accredited institution or equivalent
              as approved by divisional engineering management group
            </li>
          </ul>
          <u>Experience</u>
          <ul>
            <li>
              Three to five years of board layout, circuit design, software
              programming, debugging or testing experience.
            </li>
            <li>
              1.5 years of experience in new product development with
              demonstrated ability to produce innovative and successful designs
            </li>
          </ul>

          <u>Knowledge</u>
          <ul>
            <li>Excellent engineering/technical aptitude</li>
            <li>
              Aptitude for PCB design and drafting, preferably Altium Designer
            </li>
            <li>
              Thorough knowledge of electrical design practices and standards
            </li>
            <li>Knowledge of relevant manufacturing processes</li>
          </ul>

          <u>Skills/Competencies</u>
          <ul>
            <li>Excellent oral and written communication skills</li>
            <li>
              Ability to recognize customer product needs and trends in the
              marketplace that affect product requirements
            </li>
            <li>Capable of using and applying design and drafting standards</li>
          </ul>
          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 id="sales_manager" className="headings">
            Sales Manager
          </h2>
          <p>
            The Sales Manager is responsible for driving the profitable growth
            of Pradco’s Lubrication Equipment business.  The  Sales Manager’s
            primary focus in driving revenue growth will be the specification of
            products in Industrial, Energy, OEM Channels, direct sales, national
            accounts and distribution.  This will be achieved through the
            Account Management of key accounts and new business.  
          </p>

          <h4>Essential Duties</h4>
          <ul>
            <li>
              Identify, analyze, and prioritize target sales opportunities
              across defined market segments
            </li>
            <li>Develop and execute strategies for target accounts. </li>
            <li>
              Manage the customer relationship from platform specification to
              commercialization.
            </li>
            <li>
              Negotiate supply agreements and work with marketing on key account
              pricing strategies.
            </li>
            <li>
              Define channel, sales, and service processes to support new
              customers and implement strategies through applicable LED teams.
            </li>
            <li>
              Work closely with marketing to gather competitive product and
              market intelligence and help implement winning strategies.
            </li>
            <li>
              Work closely with marketing to identify market-focused product
              development needed to penetrate underserved industry needs.
            </li>
            <li>
              Develop and implement annual and long-term worldwide market
              development plans to support identified business strategies.
            </li>
            <li>Provide guidance to the team of Account Managers.</li>
            <li>
              Develop and maintain the key jobs database, including a detailed
              analysis of job wins and losses with recommendations for
              continuous improvement. 
            </li>
            <li>Other duties as assigned.</li>
          </ul>

          <h4>Position Requirements</h4>

          <ul>
            <li>
              Bachelor’s degree in Business, Marketing, Engineering or
              equivalent related experience
            </li>
            <li>
              5 to 8 years of successful sales experience including strategic
              account management
            </li>
            <li>
              Demonstrated ability to manage and execute through a
              cross-functional team
            </li>
            <li>
              Excellent communication skills with the ability to effectively
              interact and influence all levels of an organization
            </li>
            <li>Demonstrated leadership ability</li>
            <li>Ability to manage many strategic projects at one time</li>
            <li>
              Demonstrated skills in planning, prioritizing, problem solving and
              decision making
            </li>
            <li>Effective oral and written communication skills</li>
            <li>Excellent presentation skills</li>
            <li>
              Basic computer competency.  Familiar with Microsoft Office,
              PowerPoint or equivalent
            </li>
            <li>Willingness to travel (50%+)</li>
          </ul>
          <u>Experience</u>
          <ul>
            <li>
              Minimum of 5 years total experience developing and maintaining
              websites and hybrid/mobile applications.
            </li>
          </ul>

          <u>Skills/Abilities/Competencies</u>
          <ul>
            <li>
              Proficiency in HTML5, CSS3, Javascript ES6, REST, XML, JSON,
              ASP.Net, etc.
            </li>
            <li>
              Understanding of MVC architecture and underlying data structures
              and relationships.
            </li>
            <li>
              Experience with JS frameworks, such as Angular, React/NextJS, or
              Vue/NextJS
            </li>
            <li>
              Experience with Ionic/Capacitor, Flutter, or XCode and Android
              Studio
            </li>
            <li>
              Knowledge of CSS frameworks such Bootstrap, Material UI, Tailwind,
              etc.
            </li>
            <li>
              Knowledge of JS libraries ChartJS or D3js, jQuery, and WebGL
              preferred
            </li>
            <li>
              Ability to work with vendor support (i.e. open and manage
              incidents).
            </li>
            <li>Ability to create, maintain, and execute test plans.</li>
            <li>
              Strong analytical and problem-solving skills with an attention to
              detail.
            </li>
            <li>
              Strong team player with the interpersonal skills to work
              effectively with end-users and co-workers.
            </li>
            <li>Schedule/deadline-oriented.</li>
            <li>
              Excellent English language verbal and written communication
              skills.
            </li>
            <li>Highly motivated, self-starter desired</li>
            <li>
              Real-world web and hybrid/mobile application knowledge desired
            </li>
            <li>Ability to create development standards desired</li>
            <li>Unix shell scripting desired</li>
            <li>
              Experience with WordPress, Magento, and/or Salesforce a plus
            </li>
            <li>
              Experience with Java, J2EE, JSP, Oracle EBS, and Spring a plus
            </li>
          </ul>
          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <h2 id="territory_manager" className="headings">
            Territory Manager
          </h2>
          <h4>Purpose of Job</h4>
          <p>
            The purpose of the Territory Manager, Process position is to
            accomplish Pradco’s mission of developing and maintaining a
            profitable customer base for Pradco Process by working with
            Distributors and key End User, Material Supplier and Integrator in
            the assigned Territory (India). The objective is to meet Pradco sales
            and contribution objectives by targeting the Process Equipment
            industry segments . 
          </p>

          <h4>Job Result Areas</h4>
          <ol>
            <li>Sales growth.</li>
            <li>Distributor management and development.</li>
            <li>Develop knowledge of product, application and industries.</li>
            <li>Planning and communication.</li>
            <li>Co-ordinate Pradco opportunities through global contacts</li>
          </ol>

          <h4>Principal Activities / Duties</h4>
          <ol>
            <li>Sales:</li>
            <ul>
                <li>Grow sales and profits.</li>
                <li>Develop distributors with application expertise capable of selling application intensive products,</li>
                <li>Train distributors, end users, material suppliers, integrators on New/Upcoming Technology.</li>
                <li>Stay abreast of competitive activity (products, applications, strategies, promos) and communicate same, help determine competitive benchmarks.</li>
                <li>Demonstrate equipment and conduct training to key end-users.</li>
                <li>Implement timely and effective territory and promotion plans.</li>
                <li>Qualify and follow-up leads in a timely manner and report back on results, track.</li>
                <li>Make sales presentations on new products and application solutions to material manufactures, end users, integrators.</li>
                <li>Actively participate in, and achieve all goals in, the Asia Pacific Sales Force Activity Program.</li>
            </ul>
            <li>Distributor management and development:</li>
            <ul>
                <li>Build a distributor base of ever growing “performing” distributors (establish goals and plans to achieve goal).</li>
                <li>Develop annual sales volume targets and action plans with key distributors to increase sales / service effectiveness to end-users.</li>
                <li>Review distributor coverage annually and plans to add or delete distributors.</li>
                <li>Actively seek new speciality distributors, OEMs and other channels to improve coverage, penetration and sales of Pradco products.</li>
                <li>Develop distributors’ sales and technical abilities through joint sales and/or troubleshooting calls, training sessions on products and applications.</li>
                <li>Help distributor to specialize xxx products industry needs.</li>
                <li>Resolve conflicts, complaints and problems with distributors at all levels.</li>
            </ul>
            <li>Develop knowledge of product,  application and industries.</li>
            <ul>
                <li>Thorough knowledge on features / benefits, operation, selling and troubleshooting and application of key automotive and general industry solutions portfolio.</li>
                <li>Thorough knowledge of pump basics, concepts, product families and application technologies.</li>
                <li>Assists as required in new product testing.</li>
                <li>Thorough knowledge of Process industries and their applications.</li>
            </ul>
            <li>Planning and communication.</li>
            <ul>
                <li>Develop annual forecast and expense budgets.</li>
                <li>Develop a yearly territory sales plan and key account plan with each distributor on a quarterly basis.</li>
                <li>Follow ISO compliant and approved procedures.</li>
                <li>Administer Pradco special pricing requests from distributors.</li>
                <li>Manage demo account within agreed upon limits.</li>
            </ul>
            <li>Help co-ordinate global Pradco opportunities.</li>
            <ul>
                <li>Work closely with all industrial sales teams</li>
                <li>Communicate effectively across Pradco regions to maximize Pradco’s global sales and profitability.</li>
                <li>Develop with the regions a global planning process for Process opportunities.</li>
            </ul>

          </ol>

          <h4>POSITION REQUIREMENTS</h4>
          <u>Essential:</u>
          <ul>
            <li>To 7 years+ of sales experience.</li>
            <li>Bachelor’s degree in technical, business or marketing area or equivalent experience.</li>
            <li>Demonstrated ability to deal effectively with all organizational levels and personal styles.</li>
            <li>Evidence of in-depth understanding of the market place and competition.</li>
            <li>Effective training skills.</li>
            <li>Demonstrated ability to work successfully without close supervision.</li>
            <li>Effective communication skills – written, oral and presentation.</li>
            <li>Evidence of leadership and professionalism.</li>
            <li>Willingness to travel up to 50-60% of the time.</li>
            <li>Ability to work from a home-based office.</li>
          </ul>

          <br/>
          <u>Desirable:</u>
          <ul>
            <li>Pradco product knowledge</li>
            <li>Marketing education or experience.</li>
            <li>Master’s degree in business or engineering (mechanical, chemical or electrical)</li>
            <li>Proficiency in Regional languages</li>
            
          </ul>

          <a href="/apply">
            <button id="desc_btn">Apply</button>
          </a>

          <br />
          <br />
        </div>

        <div className="quick_job_links">
          <a href="#introduction">Mechanical Engineer</a>
          <a href="#infowecollect"> Electrical Engineer</a>
          <a href="#howweuse"> Engineer - Product & Planning</a>
          <a href="#children"> Design Engineer - Mechanical</a>
          <a href="#revisions"> CNC Machinist</a>
          <a href="#iot">Technical Information Specialist</a>
          <a href="#transferofinformation"> Clean Room Assembler</a>
          <a href="#links"> Channel Marketing Specialist</a>
          <a href="#useraccess"> Business Development Specialist</a>
          <a href="#security"> Digital marketing Specialist</a>
          <a href="#termsandmodifications">
            Communications & Design Specialist
          </a>
          <a href="#applicablelaw">Finance Specialist</a>
          <a href="#grievanceredressal"> Quality Engineer</a>
          <a href="#business_analyst">
Business Analyst - Logistics</a>
          <a href="#sde"> Software Engineer</a>
          <a href="#pcb"> PCB Design Engineer</a>
          <a href="#sales_manager"> Sales Manager</a>
          <a href="#territory_manager"> Territory Manager</a>

        </div>
      </div>
    </div>
  );
}

export default Description;
