import React from 'react'
import airless_spraytip from '../../assets/spray_tip/airless_spray.png'

function AirlessSprayTips() {
  return (
    <div>
        <div id="pic-cont">
        <img src={airless_spraytip} alt="Work Smarter" />

        <div id="acontent-r">
          <h2 id='onepass_h2'>PRADCO AIRLESS SPRAY TIPS</h2>
          <p id="img-cont-p">
          The Airless Spray Tip with SoftSpray Technology delivers a softer 
spray pattern to make it easier to deliver a professional quality finish. 
Achieve greater control and less overspray on different surfaces 
with a wide range of coatings, from stains to exterior paints.
          </p>
        </div>
      </div>
    </div>
  )
}

export default AirlessSprayTips