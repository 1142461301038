import React from "react";
import mission from "../../assets/about/mission.png";
import benefits from "../../assets/about/benefits.jpeg";
import mortar_val from "../../assets/about/mortar_values.png";

function Benefit() {
  return (
    <div>
      <h2 id="benefit_title">
        BENEFITING CUSTOMERS, COMMUNITIES AND EMPLOYEES
      </h2>
      <div className="worldclass">
        <img src={mission} id="mission_img" />
        <div id="w_content">
          <h2 id="w_h2">OUR MISSION</h2>
          <br />
          <p id="w_p">
            Our mission is to generate sustained profitable growth that benefits
            our customers, employees, shareholders and communities. We will be
            the world’s leading supplier of fluid management products and
            packages in the markets where we participate. To accomplish this
            mission, Pradco's long term growth strategies are as follows: Invest
            in New Products Target New Markets Expand Globally Make Acquisitions
          </p>
        </div>
      </div>

      <div id="h_quality">
        <img src={mortar_val} id="mortar_mobile" />
        <div id="w_content">
          <h2 id="w_h2">PRADCO’S BRICK AND MORTAR VALUES</h2>
          <br />
          <p id="w_p">
            Pradco have built and grown the company on a strong foundation of
            values that continue to define and guide everything we do for all of
            our stakeholders today. You could even say that these timeless
            values are the “brick and mortar” of Pradco. Pradco’s core values
            directly benefit our customers, employees, shareholders and
            communities: Quality Continuous Improvement Fact-based Decision
            Making Results Driven Customer Focus The result of these values is
            our promise to you, our customer: We are committed to delivering
            innovation, quality and A+ Service.
          </p>
        </div>
        <img src={mortar_val} id="mortar_web" />
      </div>

      <div className="worldclass">
        <img src={benefits} id="ben_img" />
        <div id="w_content">
          <h2 id="w_h2">EMPLOYEE BENEFITS</h2>
          <br />
          <p id="w_p">
            We believe enjoying your work and making valuable contributions to
            the company’s mission are equally important. The health, wellness,
            satisfaction and security of you and your family are vital to your
            well-being and, ultimately, to the success of Pradco. To that end, we
            are committed to offering a comprehensive benefit plan that fits
            your needs.
          </p>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
}

export default Benefit;
