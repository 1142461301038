import React from 'react'
import { Header, Footer, HeaderPC } from '../components'
import Banner from '../components/HomeOwner/banner'
import Cards from '../components/HomeOwner/cards'
import RightTip from '../components/HomeOwner/RightTip'


function HomeOwner() {
  return (
    <div className='mobile_view'>
      
        <Header/>
        <HeaderPC/>
        <Banner/>
        <Cards/>
       <RightTip/>
        <Footer/>  
    </div>
  )
}

export default HomeOwner