import React, {useState} from "react";
import email_img from "../../assets/contact/email.png";
import phone_img from "../../assets/contact/phone.png";
import upload from "../../assets/contact/upload.png";
import axios from "axios";

function Form() {
  
  const [First_Name,setFirst_Name] = useState('');
  const [Last_Name,setLast_Name] = useState('');
  const [Phone,setPhone] = useState('');
  const [Email,setEmail] = useState('');
  const [Description,setDescription] = useState('');
  const [ISD,setISD] = useState('');
  
  const [Application,setApplication] = useState(false);
  const [Distributor,setDistributor] = useState(false);
  const [ContractorPaint,setContractorPaint] = useState(false);
  const [Lubrication,setLubrication] = useState(false);
  const [Linestriping,setLinestriping] = useState(false);
  const [Oilandgas,setOilandGas] = useState(false);
  const [Foam,setFoam] = useState(false);
  const [Packaging,setPackaging] = useState(false);
  const [ProcessSanitory,setProcessSanitory] = useState(false);
  const [Homeowner,setHomeowner] = useState(false);
  const [Vehicle_Service,setVehicle_Service] = useState(false);
  const [Sealants,setSealants] = useState(false);

 const handleSubmit=(e)=>{
  e.preventDefault();
  // console.log(name,email,phone);
  const data = {
    First_Name,Last_Name, Description, ISD, Lubrication,Linestriping,Oilandgas,Foam,Packaging,ProcessSanitory,Homeowner,
    Phone,Application,Distributor,ContractorPaint,Vehicle_Service,Sealants, Email
  }
  // axios.post("https://sheet.best/api/sheets/0232ead7-ba8d-4522-8d68-561b79cd7daf",data).then(response=>{
  //   setName('');
  //   setPhone('');
  //   setEmail('');
  //   setDesc('');
  // })
  fetch("https://sheet.best/api/sheets/c14ff61b-884f-4cc9-a9f0-333bd1e48bf7", {
  method: "POST",
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(data),
})
  .then((r) => r.json())
  .then((data) => {
    // The response comes here
 
    setFirst_Name('');
    setLast_Name('');
    setPhone('');
    setEmail('');
    setDescription('');
    setISD('');
    setApplication(false);
    setDistributor(false);
    setContractorPaint(false);
    setLubrication(false);
    setLinestriping(false);
    setOilandGas(false);
    setFoam(false);
    setPackaging(false);
    setProcessSanitory(false);
    setHomeowner(false);
    setVehicle_Service(false);
    setSealants(false);

  })
  .catch((error) => {
    // Errors are reported there
    console.log(error);
  });
 }

  return (
  
    <div id="form_bg">
    
      <h1>CONTACT FORM</h1>
      <form className="form" autoComplete="off"  onSubmit={handleSubmit}>
        <div id="conrow">
          <div>
            <div>
              First Name<span id="red_star">*</span>
            </div>
            <input className="input" type="text" required autoComplete="off"
          placeholder='First Name' id='name' onChange={(e)=>setFirst_Name(e.target.value)}
          value={First_Name}
           />
          </div>

       

          <div>
            <div>
              Last Name<span id="red_star">*</span>
            </div>
            <input className="input"  placeholder='Last Name' type="text" name="phone" autoComplete="off" required onChange={(e)=>setLast_Name(e.target.value)}
          value={Last_Name} />
          </div>
        </div>
        

        <div id="conrow">
          <div>
            <div>
              Email<span id="red_star">*</span>
            </div>
            <div id="combined_ip">
              <div id="mail_grey">
                <img src={email_img} id="mail_img" />
              </div>
              <input
                className="input_2"
                type="text" required
                placeholder="name@example.com" autoComplete="off"  onChange={(e)=>setEmail(e.target.value)}
                value={Email}
              />{" "}
            </div>{" "}
          </div>

          <div>
            <div>
              Phone<span id="red_star">*</span>
            </div>
            
            
              <input
                className="input_isd"
                type="text" required
                placeholder="ISD code" onChange={(e)=>setISD(e.target.value)}
                value={ISD}
              />
              
              <input
                className="input_phone" required
                type="text"
                placeholder="99876 54321" onChange={(e)=>setPhone(e.target.value)}
                value={Phone}
              />{" "}
         
          </div>
        </div>
      

        <div id="conrow">
          <div className="checkb">
        <input
          type="checkbox"
         
          value={Distributor}

          checked={Distributor}
          onChange={(e)=>setDistributor(e.target.checked)}
        /> For Distributionship
        </div>

        

        <div className="checkR">
        <input
          type="checkbox"
          checked={Application}
          value={Application}
          //checked={isChecked}
          onChange={(e)=>setApplication(e.target.checked)}
        /> For Material or Application
        </div>

        </div>

        
        <h3 className="caser">Case Reason</h3>

        <div id="conrow">
          <div className="checkb">
        <input
          type="checkbox"
          checked={ContractorPaint}
          value={ContractorPaint}
          //checked={isChecked}
          onChange={(e)=>setContractorPaint(e.target.checked)}
        /> Contractor - Paint, Texture & Pressure Washing
        
        </div>

        

        <div className="checkR">
        <input
          type="checkbox"
          checked={Lubrication}
          value={Lubrication}
          //checked={isChecked}
          onChange={(e)=>setLubrication(e.target.checked)}
        /> Automatic Lubrication Equipment
        </div>

        </div>

        {/* ROW 2 */}
      
        <div id="conrow">
          <div className="checkb">
        <input
          type="checkbox"
          checked={Linestriping}
          value={Linestriping}
          //checked={isChecked}
          onChange={(e)=>setLinestriping(e.target.checked)}
        /> Contractor - Line Striping
        
        
        </div>

        

        <div className="checkR">
        <input
          type="checkbox"
          checked={Oilandgas}
          value={Oilandgas}
          //checked={isChecked}
          onChange={(e)=>setOilandGas(e.target.checked)}
        /> Oil & Natural Gas - Chemical Injection Pumps
        
        </div>

        </div>


        {/* ROW 3 */}
      
        <div id="conrow">
          <div className="checkb">
        <input
          type="checkbox"
          checked={Foam}
          value={Foam}
          //checked={isChecked}
          onChange={(e)=>setFoam(e.target.checked)}
        /> Foam, Coatings, Blast, ToughTek

        </div>        

        <div className="checkR">
        <input
          type="checkbox"     
          value={Packaging}
          checked={Packaging}
          //checked={isChecked}
          onChange={(e)=>setPackaging(e.target.checked)}
        /> Packaging - Case & Carton Sealing
        </div>

        </div>

        {/* ROW 4 */}
      
        <div id="conrow">
          <div className="checkb">
        <input
          type="checkbox"
          checked={Homeowner}
          value={Homeowner}
          //checked={isChecked}
          onChange={(e)=>setHomeowner(e.target.checked)}
        /> Homeowner/DIY - Paint       
        </div>        

        <div className="checkR">
        <input
          type="checkbox"   
          checked={ProcessSanitory}  
          value={ProcessSanitory}
          //checked={isChecked}
          onChange={(e)=>setProcessSanitory(e.target.checked)}
        /> Process and Sanitory Equipments        
        </div>
        </div>    

      {/* ROW 5 */}
      
      <div id="conrow">
          <div className="checkb">
        <input
          type="checkbox"
          checked={Vehicle_Service}
          value={Vehicle_Service}
          //checked={isChecked}
          onChange={(e)=>setVehicle_Service(e.target.checked)}
        /> Vehicle Service Equipment          
        </div>        

        <div className="checkR">
        <input
          type="checkbox"     
          value={Sealants}
          checked={Sealants}
          //checked={isChecked}
          onChange={(e)=>setSealants(e.target.checked)}
        /> Sealants & Adhesives, Composites, LSR        
        </div>
        </div>
    


<br/>
        
        <div id="conrow">
          <div>Description</div>
          <textarea name = 'desc' rows="7" cols="30" className="text_area" placeholder="Max limit : 500 characters" onChange={(e)=>setDescription(e.target.value)}
          value={Description} maxlength="500"></textarea>
        </div>


      

        <div id="form_bottom_grey"> 
        <a>
          <button id='send_btn' type="submit" >Send</button>
        </a></div>
      </form>
    </div>
  );
}

export default Form;


  //   const scriptURL = 'https://script.google.com/macros/s/AKfycbxiN_4OFH9PMmCMXCofEnPog0J_5h6Yth5qnLfFajxrfH-VS1uccfit2-Y6HnK9Dz6Sjw/exec'
  //   const form = document.forms['google-sheet']
   
  //  form.addEventListener('submit', e => {
  //    e.preventDefault()
  //    fetch(scriptURL, { method: 'POST', body: new FormData(form)})
  //      .then(response => alert("Thanks!! I'll get in touch with you soon..."))
  //      .catch(error => console.error('Error!', error.message))
  //  })
   




