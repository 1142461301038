import React from "react";
import clean from "../../assets/agri_pressure/clean.png";

function Clean() {
  return (
    <div>
      <div id="pic-cont">
        <img src={clean} alt="Work Smarter" />

        <div id="acontent-r">
          <h2 id="onepass_h2"  style={{marginTop:'-3%'}}>
            Depend on Pradco for Your <br/> Professional Pressure Washer Needs
          </h2>
          <p id="aimg-cont-p">
            Complement your paint sprayer with a Pradco professional pressure
            washer designed to prep and clean any painting surface. Pradco
            pressure washers are also easy to move around—featuring the lightest
            of heavy-gauge aluminum frames. It’s simple to choose one of our
            pressure washers that most closely matches your needs. And because
            it’s a Pradco, our rugged pressure washers are engineered and built
            with the best parts, powered by super quality and backed by the
            industry’s best warranty.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Clean;
