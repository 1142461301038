import React from 'react'
import Accordion from '../components/FAQ/Accordion'
import { accordionData } from '../components/FAQ/content';
import {HeaderPC, Header, Footer} from '../components';
function FAQ() {
  return (
    <div>
        <HeaderPC/>
        <Header/>
        <div className='faq_grey'>
            <h1 >FREQUENTLY ASKED QUESTIONS</h1>
        </div>
      <div className="accordion">
        {accordionData.map(({ title, content }) => (
          <Accordion title={title} content={content} />
        ))}
      </div>
      <br/>
      <Footer/>
        
    </div>
  )
}

export default FAQ