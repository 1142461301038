import React from 'react'
import Banner from '../components/Industry/banner'
import {Header, Footer, HeaderPC} from '../components'
import Cards from '../components/Industry/Cards.js'
import Difference from '../components/Industry/Difference'
import '../components/Industry/industry.css'
function Industry() {
  return (
    <div className='mobile_view'>
         <Header/> 
         <HeaderPC/>
         <Banner/>
         <Cards/>
         <Difference/>    
        
        <Footer/>
    </div>
  )
}

export default Industry