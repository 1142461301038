import React from "react";
import "./contractor.css";
import section3 from "../../assets/contractor/section3.png";
function Equipment() {
  return (
    <div>
      <h2 id="last_title">HIGH PERFORMING EQUIPMENT FOR PROFESSIONALS</h2>
      <div className="section3">
        <div>
          <img src={section3} id="last_img"/>
        </div>
        <div id="last_sec_content">
          <h3>INCREASE YOUR PRODUCTIVITY</h3> <p id="section3_p">Pradco contractor products and accessories
          are designed for professionals who care about quality and
          productivity. Work faster and deliver high quality results.</p>
            <br/>
          <h3>HIGH QUALITY PRODUCTS</h3>
            <p  id="section3_p">We design and manufacture products built from the highest quality parts and components.</p>
        </div>
      </div>
    </div>
  );
}

export default Equipment;
