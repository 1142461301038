import React from "react";
import company from "../../assets/about/company.png";
import brand_promise from "../../assets/about/brand_promise.png";
import culture from "../../assets/about/culture.png";
import business from "../../assets/about/business.png";

function Promise() {
  return (
    <div>
      <div className="products_solutions">
        <h1 id="prod_soln_title">OUR PROMISE TO YOU</h1>

        <div className="card-list">
          {/* CARD 1  */}

          <div className="card">
            <img src={company} />
            <div className="card34_title">COMPANY INFORMATION</div>
            <p>
              Pradco manufactures and markets premium equipment to move,
              measure, control, dispense and spray a wide variety of fluids.
            </p>
          </div>

          {/* CARD 2 */}
          <div className="card">
          
              <img src={brand_promise} />
              <div className="card34_title">OUR BRAND PROMISE</div>
              <p>
                We’ve built and grown Pradco on a strong foundation of values
                that continue to define and guide everything we do for our
                customers and stakeholders today. The result—Innovation, Quality
                and A+ Serviceis our promise to you, our customer.
              </p>
          
          </div>

          {/* CARD 3 */}
          <div className="card">
         
              <img src={culture} />
              <div className="card34_title">OUR CULTURE</div>
              <p>
                Pradco employees take pride in their contributions and share our
                vision and commitment to delivering the highest quality products
                and services in the industry. We strive for continuous
                improvement and create new and exciting products as efficiently
                as possible.
              </p>
       
          </div>

          {/* CARD 4 */}
          <div className="card">
           
              <img src={business} />
              <div className="card34_title">BUSINESS PRACTICES</div>
              <p>
                From our code of conduct and commitments to investors to our
                approach to sustainability, we are a company that operates on
                strong principles shared by all employees.
              </p>
        
          </div>
        </div>
      </div>
    </div>
  );
}

export default Promise;
