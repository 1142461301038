import React from "react";
import easier from '../../assets/paint_sprayers/easier.png'
import clock from '../../assets/paint_sprayers/clock.png'
import thumbs_up from '../../assets/paint_sprayers/thumbs_up.png'

function WhySpray() {
  return (
    <div>
      <h1 id="why_spray_title">WHY SPRAY?</h1>

      <div id="cont3">
        <div id="subcont">
        <div id="text-icon"><h2>FASTER</h2><img src={clock} id='icon-img'/> </div>
          <p>
            With Pradco’s One-Pass Coverage, you’ll be painting 7 to 15 times
            faster than a brush and roller. With an electric pump and motor
            doing the work, your arm and back muscles will feel better. You’ll
            also save time by not setting up paint trays and tools before the
            job or cleaning brushes afterward.
          </p>
        </div>
        <div id="subcont">
        <div id="text-icon"><h2>BETTER</h2><img src={thumbs_up} id='icon-img'/> </div>
          <p>
            With a Pradco paint sprayer, you’ll get a perfect, seamless finish.
            Hard-to-paint surfaces like picket fences, deck railings and
            furniture become remarkably easy to finish with a paint sprayer in
            your hand.
          </p>
        </div>
        <div id="subcont">
          <div id="text-icon"><h2>EASIER</h2><img src={easier} id='icon-img'/> </div>
          <p>
            When you use a paint sprayer, it’s much easier than painting with a
            brush or roller. Your body will thank you for not having to bend
            down and refill a tray or load up your brush with paint every few
            minutes. No drips, no messy trays, no messy clean up.
          </p>
        </div>
      </div>
    </div>
  );
}

export default WhySpray;
