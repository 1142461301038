import React from "react";
import value from "../../assets/home/value.png";
function Values() {
  return (
    <div className="values_customers">
      <h2 id="val_title">OUR VALUES</h2>
      <hr id="blue-line" />

      <div className="val_cust_content">
        <img src={value} id="val_img" />
        <div id='val_content'>
        <h3 id="val_tag">
          AT PRADCO, ENGINEERING DRIVES 
          BEST-IN-CLASS QUALITY
        </h3>

        <p>
          We’ve built the company on the idea that great engineering and
          meticulous manufacturing leads to high quality, long lasting products.
          Our customers know
          that behind every product delivery is a promise of exceptional
          customer service.
        </p>   
        <a className="cust_learn_more" href="/about">
        LEARN MORE {">"}
        <hr id="learn-blue-line" />
      </a>       
        </div>
        
      </div>
     
    </div>
  );
}

export default Values;
