import React from 'react'
import { Header, Footer, HeaderPC } from '../components'
import Banner from '../components/Vehicle/banner'
import Cards from '../components/Vehicle/cards'
import '../components/Vehicle/vehicle.css'
import AlwaysOn from '../components/Vehicle/AlwaysOn'
import Clean from '../components/Vehicle/clean'

function Vehicle() {
  return (
    <div className='mobile_view'>
      <HeaderPC/>
        <Header/>        
        <Banner/>
        <Clean/>
        <Cards/>
        <AlwaysOn/>
        <Footer/>
    </div>
  )
}

export default Vehicle