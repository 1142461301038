import React from 'react'
import {Header, Footer, HeaderPC} from '../../components'
import JDBanner from './jdbanner'
import JobAccordion from './JobAccordion'
import { accordionData } from '../../components/Careers/content';
import Description from './Description';

function JobDescription() {
  return (
    <div>
        <Header/>
        <HeaderPC/>
      <JDBanner/>
      <Description/>
      
      {/* <div className="accordion">
        {accordionData.map(({ title, content }) => (
          <JobAccordion title={title} content={content} />
        ))}
      </div> */}

        <Footer/>
    </div>
  )
}

export default JobDescription