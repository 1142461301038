import React from 'react'
import {Header,Footer, HeaderPC} from '../components'
import Terms from '../components/Terms/terms'
import '../components/Terms/terms.css'

function TermsOfUse() {
  return (
    <div className='mobile_view'>
        <Header/>
        <HeaderPC/>
        <Terms/>
        <Footer/>
    </div>
  )
}

export default TermsOfUse