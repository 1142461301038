import React from "react";
import home_banner from "../../assets/home/banner.png";
import hban from "../../assets/home/ban.svg";
import sprayx from "../../assets/home/sprayx.png";
import home_banner_mobile from "../../assets/home/mob_banner.svg";
function Banner() {
  return (
    <div className="bg">
      {/* <img src={hban} className="banner" /> */}
      <img className="banner-mobile" src={home_banner_mobile} />
      
      <div id="background">
        <a href="/about">
          <button id="banner_btn">GET TO KNOW PRADCO</button>
        </a>
      </div>
    </div>
  );
}

export default Banner;
