import React from 'react'
import machine1 from '../../assets/paint_sprayers/machine_1.png'
import machine2 from '../../assets/paint_sprayers/machine_2.png'
import machine3 from '../../assets/paint_sprayers/machine3.png'
import contract from '../../assets/paint_sprayers/contract_paint.png'
function Machines() {
  return (
      <div>
    <div id='machines'>
        <img src={machine1}/>
        <img src={machine2}/>
        <img src={machine3}/>

    </div>
    
    <div className="pslast_section">
    
        <div className="psblue-card">
       
          <h2 id="psblue-card-title">
          LOOKING FOR OUR SELECTION OF CONTRACTOR PAINT SPRAYERS?
          </h2>
          <p id="blue-content">
          Whether you’re a contractor focused on small residential or a large contractor tackling the biggest projects every day, Pradco offers you the best, most extensive line of painting equipment to meet your needs.
          </p>
          <a href='/product_catalogue'><button id="psblue_btn">View Paint Sprayers
          </button></a>
        </div>
        <img src={contract} id='blue-img'/>
      </div>

    </div>
  )
}

export default Machines