import React from "react";
import heavy_duty from "../../assets/catalogue/heavy_duty.png";
import tip_extension from "../../assets/catalogue/tip_extension.png";
import pressure_roller from "../../assets/catalogue/pressure_roller.png";
import roller_cover from "../../assets/catalogue/roller_cover.png";
import mesh60 from "../../assets/catalogue/60mesh.png";
import handheld from "../../assets/catalogue/handheld.png";
import inlet from "../../assets/catalogue/inlet.png";
import mesh100 from "../../assets/catalogue/mesh100.png";
import spray_gun from "../../assets/catalogue/spray_gun.png";
import stip from "../../assets/catalogue/415.png";
import tip_guard from "../../assets/catalogue/tip_guard.png";
import gasket from "../../assets/catalogue/gasket.png";
import endurance from "../../assets/catalogue/endurance.png";
import duraflex from "../../assets/catalogue/duraflex.png";
import whip from "../../assets/catalogue/whip.png";
import connector from "../../assets/catalogue/connector.png";
import repair from "../../assets/catalogue/repair.png";
import suction_tube from "../../assets/catalogue/suction_tube.png";
import stand from "../../assets/catalogue/stand.png";
import conversion from "../../assets/catalogue/conversion.png";
import battery_1 from "../../assets/catalogue/battery_1.png";
import charger1 from "../../assets/catalogue/charger1.png";
import storage from "../../assets/catalogue/storage.png";

function Products() {
  return (
    <div>
      <h1 id="pg_title">EXTENSIONS & ROLLERS</h1>
      <p id="hoses_p">
        Extend your reach and eliminate ladder hassles to safely spray
        hard-to-reach areas like high walls, vaulted ceilings, decks, railings
        and soffits with Pradco’s full line of extended reach accessories.
      </p>
      <br />
      <div className="ps_cards">
        {/* CARD */}
        <div className="ps_card">
          <img src={heavy_duty} alt="SprayX 490" />
          <h3>
            Heavy Duty Extension
            <br /> 101.6 cm (40 in)
          </h3>
        </div>

        <div className="ps_card">
          <img src={tip_extension} alt="SprayX 490" />
          <h3>
            Spray Tip Extension
            <br /> 38.1 cm, 15 in
          </h3>
        </div>

        <div className="ps_card">
          <img src={pressure_roller} alt="SprayX 490" />
          <h3>Pressure Roller Kit</h3>
        </div>

        <div className="ps_card">
          <img src={roller_cover} alt="SprayX 490" />
          <h3>
            Roller Cover <br />
            1.27 cm (1/2 in) nap
          </h3>
        </div>
        <br />
        <br />
      </div>

      {/* FILTERS & STAINERS */}

      <h1 id="pg_title">FILTERS & STAINERS</h1>
      <p id="hoses_p">
        The easiest way to maximise spray performance, extend the life of your
        equipment and get the professional quality finish you want is to use
        genuine Pradco filters and strainers in your sprayers and guns.
      </p>
      <br />
      <br />

      <div className="ps_cards">
        {/* CARD */}
        <div className="ps_card">
          <img src={mesh100} alt="SprayX 490" />
          <h3>Gun Filter 100 mesh</h3>
        </div>

        <div className="ps_card">
          <img src={mesh60} alt="SprayX 490" />
          <h3>Gun Filter 60 mesh</h3>
        </div>

        <div className="ps_card">
          <img src={inlet} alt="SprayX 490" />
          <h3>Inlet Strainer for Pro210ES</h3>
        </div>

        <div className="ps_card">
          <img src={handheld} alt="SprayX 490" />
          <h3>Handheld Filter 100 mesh</h3>
        </div>
        <br />
        <br />
      </div>

      <h1 id="pg_title">GUNS, SPRAY TIPS & GUARDS</h1>
      <p id="hoses_p">
        Pradco’s well-balanced and lightweight airless spray guns provide a
        comfortable spraying experience for all of your projects. The in-handle
        paint filter reduces tip clogs and improves the quality of your finish.
      </p>
      <br />
      <br />

      <div className="ps_cards">
        {/* CARD */}
        <div className="ps_card">
          <img src={spray_gun} alt="SprayX 490" />
          <h3>Spray Gun</h3>
        </div>

        <div className="ps_card">
          <img src={stip} alt="SprayX 490" />
          <h3>SprayX 415 spray tip</h3>
        </div>

        <div className="ps_card">
          <img src={tip_guard} alt="SprayX 490" />
          <h3>Spray Tip Guard</h3>
        </div>

        <div className="ps_card">
          <img src={gasket} alt="SprayX 490" />
          <h3>Single Seal Gasket</h3>
        </div>
        <br />
        <br />
      </div>

      <h1 id="pg_title">HOSES & PUMPS</h1>
      <p id="hoses_p">
        Available in 7.6 and 15 m (25 and 50 ft) lengths, Pradco DuraFlex
        airless hoses are durable, flexible and designed to ensure you get the
        best results from your airless sprayer. Easily replace your pump
        <br /> in a matter of minutes to avoid downtime and finish faster with
        Pradco’s fast and easy pump replacement systems.
      </p>
      <br />
      <br />

      <div className="ps_cards">
        {/* CARD */}
        <div className="ps_card">
          <img src={endurance} alt="SprayX 490" />
          <h3>Endurance Pump Lower Kit for Pro210ES</h3>
        </div>

        <div className="ps_card">
          <img src={whip} alt="SprayX 490" />
          <h3>
            DuraFlex Whip Hose <br /> 4.8 mm x 1.2 m (4 ft)
          </h3>
        </div>

        <div className="ps_card">
          <img src={connector} alt="SprayX 490" />
          <h3>
            Hose Connector
            <br /> 6.35 mm x 6.35 mm
          </h3>
        </div>

        <div className="ps_card">
          <img src={duraflex} alt="SprayX 490" />
          <h3>
            DuraFlex Hose
            <br /> 1/4 in x 15 m (50 ft)
          </h3>
        </div>
        <br />
        <br />
      </div>

      <h1 id="pg_title">LACQUER CONVERSION KITS & SUCTION TUBE SETS</h1>
      <p id="hoses_p">
        These conversion kits allow you to spray all types of lacquers and flush
        with lacquer thinner when tackling projects with Pradco Magnum ProX17
        and ProX19 cart models. Replacement suction tube sets come in handy when
        using your sprayer often or if the existing tube set becomes damaged
        during a project.
      </p>
      <br />
      <br />

      <div className="ps_cards">
        {/* CARD */}
        <div className="ps_card">
          <img src={conversion} alt="SprayX 490" />
          <h3>Lacquer Conversion Kit for Cart Models</h3>
        </div>

        <div className="ps_card">
          <img src={stand} alt="SprayX 490" />
          <h3>Lacquer Conversion Kit for Stand Models</h3>
        </div>

        <div className="ps_card">
          <img src={repair} alt="SprayX 490" />
          <h3>Repair Kit, Suction Tube, ProX17, ProX19, Stand</h3>
        </div>

        <div className="ps_card">
          <img src={suction_tube} alt="SprayX 490" />
          <h3>Suction Tube Set for Cart Models</h3>
        </div>
        <br />
        <br />
      </div>

      <h1 id="pg_title">BATTERIES, STORAGE FLUID & CASES</h1>
      <p id="hoses_p">
        Top quality Lithium-ion batteries and chargers provide the power you
        need to keep spraying longer so you can finish your projects faster.
      </p>
      <br />
      <br />

      <div className="ps_cards">
        {/* CARD */}
        <div className="ps_card">
          <img src={endurance} alt="SprayX 490" />
          <h3>Pump Armour 0.95 l (1 qt)</h3>
        </div>

        <div className="ps_card">
          <img src={battery_1} alt="SprayX 490" />
          <h3>
          Lithium-ion Battery Pack 230V
          </h3>
        </div>

        <div className="ps_card">
          <img src={charger1} alt="SprayX 490" />
          <h3>
          Battery Charger
          </h3>
        </div>

        <div className="ps_card">
          <img src={storage} alt="SprayX 490" />
          <h3>
          Storage Case
          </h3>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}

export default Products;
