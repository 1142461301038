import React from "react";
import contact_banner from "../../assets/contact/contact_banner.png";
import banner_mobile from "../../assets/contact/banner_mobile.png";
function Banner() {
  return (
    <div>
      <div id="banner_mobile_hid">
      <h4 class="banner_tag">CONTACT US</h4>
      <h1 id="conbanner_h1">A+ CUSTOMER SERVICE—WE HAVE YOUR BACK</h1>
      <p id="conbanner_p">
        No matter your question or challenge, we always have your back with
        industry-leading customer service and technical support. We call it A+
        Customer Service
      </p></div>
      <img src={contact_banner} alt="Home Owner" id="banner_soln" />
      <img src={banner_mobile} alt="Home Owner" id="banner_soln_mobile" />

      <h1 id="title_1">PRADCO - CONTACT US</h1>
      <p id="tag">
      Our customer service is here to provide support  answer questions and help address your needs.
      </p>
    </div>
  );
}

export default Banner;
