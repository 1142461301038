import React, { useState } from "react";

function CeoMail() {
  const [Name, setName] = useState("");
  const [ISD, setISD] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Description, setDescription] = useState("");
  const [Feedback, setFeedback] = useState(false);
  const [Escalation, setEscalation] = useState(false);
  const [Type, setType] = useState();

  const inputs = ["Feedback", "Escalation"];

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name,email,phone);
    const data = {
      Name,
      Description,
      Subject,
      ISD,
      Type,
      Phone,
      Email,
      Feedback,
      Escalation,
    };

    fetch(
      "https://sheet.best/api/sheets/6b80d815-adfe-45c7-8c14-a00b041e71ac",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((r) => r.json())
      .then((data) => {
        // The response comes here

        setName("");
        setSubject("");
        setPhone("");
        setISD("");
        setEmail("");
        setDescription("");
        setType('');
        setEscalation("");
        setFeedback("");
      })
      .catch((error) => {
        // Errors are reported there
        console.log(error);
      });
  };

  return (
    <div>
      <div id="form_bg">
        <form className="form" autoComplete="off" onSubmit={handleSubmit}>
          <h1 id="ceoh1">EMAIL THE CEO</h1>
          <div id="conrow">
            <div>
              <div>
                Name<span id="red_star">*</span>
              </div>
              <input
                className="input"
                type="text"
                required
                autoComplete="off"
                placeholder="Name"
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={Name}
              />
            </div>

            <div>
              <div>
                Email<span id="red_star">*</span>
              </div>
              <input
                className="input"
                placeholder="Email"
                type="email"
                name="phone"
                autoComplete="off"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={Email}
              />
            </div>
          </div>

          <div id="conrow">
            <div>
              <div>
                Phone<span id="red_star">*</span>
              </div>
              <input
                className="input_isd"
                type="text"
                required
                placeholder="ISD code"
                onChange={(e) => setISD(e.target.value)}
                value={ISD}
              />
              <input
                className="input_phone"
                required
                type="text"
                placeholder="99876 54321"
                onChange={(e) => setPhone(e.target.value)}
                value={Phone}
              />{" "}
            </div>

            <div>
              <div id='sub'>
                Subject<span id="red_star">*</span>
              </div>

              <div className="radio_flex">
                
                <div>
                <input
                  name="Type"
                  
                  type="radio"
                  value="Feedback"
                  onChange={(e) => setType(e.target.value)}
                /> Feedback</div>
                <div>
                  <input
                    type="radio"
                    name="Type"
                    onChange={(e) => setType(e.target.value)}
                    value="Escalation"
                  /> Escalation
                </div>
              </div>
            </div>
          </div>

          <div id="conrow">
            <div>Description</div>
            <textarea
              name="desc"
              rows="7"
              cols="30"
              placeholder="Max limit : 500 characters"
              className="text_area"
              onChange={(e) => setDescription(e.target.value)}
              value={Description}
              maxlength="500"
            ></textarea>
          </div>
          <br />
          <a>
            <button id="send_btn" type="submit">
              Send
            </button>
          </a>
          <br />
          <br />
        </form>
      </div>
    </div>
  );
}

export default CeoMail;
