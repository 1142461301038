import React from "react";

function Team() {
  return (
    <div id="ash">
      <h3 id="ash_tag">Work at Pradco</h3>
      <h1 id="ash_title">Join a team and inspire the work.</h1>
      <p id="ash_tag">
        Discover how you can make an impact: See our areas of work, worldwide
        locations, and opportunities for you.
      </p><br/>

      <div className="flx">
             <div className="divcont">
          <h3>Professional and Personal Growth</h3>
          <h1>Starting here is just the beginning.</h1>
          <p>
            People are at the heart of Pradco and we want you to thrive. From
            day one, you’ll have the opportunity to make meaningful
            contributions to the big picture. You can set yourself up for
            success with our career development framework, as well as guidance
            and resources that include course offerings from trainings
          </p>
        </div>

        <div  className="divcont">
          <h3>Inclusion</h3>
          <h1>Everybody in.</h1>
          <p>
            Diversity includes. Inclusion empowers. And when we bring everybody
            in, we can do the best work of our lives together. That’s why we
            continue to strengthen our long-standing commitment to efforts such
            as inclusive hiring and development and equitable pay for all.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Team;
