import React from "react";
import banner from "../../assets/home_owner/banner.png";
import "./homeowner.css";
import home_o_banner_mob from '../../assets/home_owner/hbanner_mobile.png'
function Banner() {
  return (
    <div >
      
      <div id="desktop_only">
      <h4 class="banner_tag">TIME TO RETIRE YOUR BRUSH & ROLLER</h4>
      <h1 id="banner_h1">
        PRODUCTS FOR <br />
        HOMEOWNERS
      </h1>
      <p id="carbanner_p">
        Use a Pradco paint sprayer and cut your painting time in half compared
        to using a brush and roller. And at the same time, create a flawless,
        professional finish.
      </p>
      </div>
      <img src={banner} alt="Home Owner" id="banner_soln" />
      <img src={home_o_banner_mob} alt="Home Owner" id="banner_soln_mobile" />

      <h1 id="title_1">PAINT SPRAYERS THAT TACKLE ALL YOUR PROJECTS</h1>
      <p id="tag">Your Pradco paint sprayer can handle all your interior and exterior painting projects.</p>
     <br/>
    
    </div>
  );
}

export default Banner;
