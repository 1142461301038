import React from "react";
import banner from "../../assets/careers/banner.png";
import banner_mob from "../../assets/careers/jdbanner_mob.png";
function JDBanner() {
  return (
    <div>
      <div id="banner_mobile_hid">
        {/* <h1 id="jdb_h">MEET OUR TALENTS</h1>
        <p id="jdb_p">
          At Pradco, our commitment to quality begins with the employees we add
          to our teams around the globe. We hire people who are passionate about
          creating and supporting high-quality, innovative products and
          solutions that address customer problems and challenges. Our dedicated
          team members reflect our culture and lead with a sure sense of ethics
          and integrity.
        </p> */}
      </div>
      <img src={banner} alt="Industrial Manufacturing" id="banner_soln" />
      <img
        src={banner_mob}
        alt="Industrial Manufacturing"
        id="banner_soln_mobile"
      />
      
      <h1 id="title_1">SEE WHERE YOU FIT IN</h1>
      <p id="jd_tag" style={{marginTop:'-1%'}}>
    From marketing to machining and sales to engineering, we're looking for smart, driven people who are passionate about creating and supporting innovative products for our global offices and sales territory job openings.
      </p><br/><br/>
    </div>
  );
}

export default JDBanner;
