import React from "react";
import Industry_banner from "../../assets/industry/indus.png";
import indus_mobile from "../../assets/industry/indus_mobile.png"

function Banner() {
  return (
    <div>
       <div id="banner_mobile_hid">
      <h1 id="banner_h1">
      MOVING YOUR <br/>MANUFACTURING FORWARD
      </h1>
      <p id="banner_p">
        Customers in industrial, manufacturing and processing industries such as
        automotive, aerospace, packaging, food and beverage, oil and natural gas
        and many others rely on Pradco's expertise in liquid finishing, sealants
        and adhesives, lubrication, sanitary, fluid transfer, chemical injection
        and more.
      </p></div>
      <img src={Industry_banner} alt="Home Owner" id="banner_soln" />
      <img src={indus_mobile} alt="Industrial Manufacturing" id="banner_soln_mobile" />

      <h1 id="title_1">EQUIPMENT FOR ALL INDUSTRIAL & MANUFACTURING APPLICATIONS</h1>
      <p id="tag">
      We engineer and manufacture products and accessories to support a wide range of in-plant manufacturing materials and applications. 
      </p>
     
    </div>
  );
}

export default Banner;
