import React from "react";
import banner from "../../assets/careers/careers.png";
import banner_mobile from "../../assets/careers/banner_mobile.png";

function Banner() {
  return (
    <div>
      <div id="banner_mobile_hid">
     {/* <h1 id="carbanner_h1">PRADCO CAREERS</h1>
      <p id="carbanner_p">
        If you want to learn more and grow in your career, we’re looking for
        candidates who are committed to quality, innovation and solving customer
        problems. Join a fast-moving, growing company.
      </p>
      <h1 id='join'>Join Us. Be You.</h1>*/} </div> 
      <img src={banner} alt="Home Owner" id="banner_soln" />
      <img src={banner_mobile} alt="Home Owner" id="banner_soln_mobile" />

      
    </div>
  );
}

export default Banner;
