import React from 'react'
import {Header, Footer, HeaderPC} from '../components'
import Banner from '../components/ProductCatalogue/banner'
import SprayMachines from '../components/ProductCatalogue/spraymac'
import Products from '../components/ProductCatalogue/products'
import '../components/ProductCatalogue/productCat.css'
function ProductCatalogue() {
  return (
    <div className='mobile_view'>
      <Header/>
      <HeaderPC/>
      <Banner/>
      <SprayMachines/>
      <Products/>
      <Footer/>
    </div>
  )
}

export default ProductCatalogue