import React from 'react'
import veltech from '../../assets/home/veltech.jpeg'
import nstedb from '../../assets/home/nstedb.png'
import crescent from '../../assets/home/crescent.png'
import pdeu from '../../assets/home/pdeu.jpeg'
import makeindia from '../../assets/home/makeindia.png'
import startupind from '../../assets/home/startupind.png'
import starttn from '../../assets/home/starttn.png'

function Funded() {
  return (
    <>
    <div className='fund_flex'>
        <div>
        <h2 id="back">BACKED BY </h2>
        <hr id="fund_line" />
        <h1 id='backed_by' >WE'RE BACKED BY</h1>
        </div>
        <div  className='fund_flex'>
            <img src={startupind} alt='VelTech incubator' loading='lazy' className='startind'/>
            <img src={starttn} alt='VelTech incubator' loading='lazy' className='starttn' />
            </div> 
        
    </div>
         <div  className='fund_flex'>

         <div id="space" style={{width:"40%"}} />
         <img src={nstedb} alt='NSTEDB emblem' loading='lazy' className='fund_img'/>
         <img src={makeindia} alt='VelTech incubator' loading='lazy' className='makeindia'/>
       
     </div>
     <br/><br/>
     </>
  )
}

export default Funded