import React,{useState} from 'react'
import email_img from "../../assets/contact/email.png";
import JDBanner from './jdbanner';
import {Header, Footer, HeaderPC} from '../../components'


function Apply() {

    
  const [First_Name,setFirst_Name] = useState('');
  const [Last_Name,setLast_Name] = useState('');
  const [Phone,setPhone] = useState('');
  const [Email,setEmail] = useState('');
  const [Description,setDescription] = useState('');
  const [ISD,setISD] = useState('');
  const [Type, setType] = useState();
  const [Linkedin, setLinkedin] = useState('');
  const [Experience, setExperience] = useState('');
  const [Domain, setDomain] = useState('');

  
 const handleSubmit=(e)=>{
    e.preventDefault();
    // console.log(name,email,phone);
    const data = {
      First_Name,Last_Name, Description, ISD,  Phone, Email,Type,Experience, Domain, Linkedin
    }
 
    fetch("https://sheet.best/api/sheets/ed37a9f1-ee1e-4551-96fa-de3124ed9c1a", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((r) => r.json())
    .then((data) => {
      // The response comes here
   
      setFirst_Name('');
      setLast_Name('');
      setPhone('');
      setEmail('');
      setDescription('');
      setISD('');
      setLinkedin('');
      
  
    })
    .catch((error) => {
      // Errors are reported there
      console.log(error);
    });
   }
  
  return (
    <div>
        <Header/>
        <HeaderPC/>
        <JDBanner/>

        <div id="form_bg" style={{paddingTop:'1%', paddingBottom:'1%'}}>
    
    <h1 >JOB APPLICATION</h1>
    <form className="form" autoComplete="off" style={{marginTop:'-2%'}}  onSubmit={handleSubmit}>
      <div id="conrow">
        <div>
          <div>
            First Name<span id="red_star">*</span>
          </div>
          <input className="input" type="text" required autoComplete="off"
        placeholder='First Name' id='name' onChange={(e)=>setFirst_Name(e.target.value)}
        value={First_Name}
         />
        </div>

     

        <div>
          <div>
            Last Name<span id="red_star">*</span>
          </div>
          <input className="input"  placeholder='Last Name' type="text" name="phone" autoComplete="off" required onChange={(e)=>setLast_Name(e.target.value)}
        value={Last_Name} />
        </div>
      </div>
      

      <div id="conrow">
        <div>
          <div>
            Email<span id="red_star">*</span>
          </div>
          <div id="combined_ip">
            <div id="mail_grey">
              <img src={email_img} id="mail_img" />
            </div>
            <input
              className="input_2"
              type="text" required
              placeholder="name@example.com" autoComplete="off"  onChange={(e)=>setEmail(e.target.value)}
              value={Email}
            />{" "}
          </div>{" "}
        </div>

        <div>
          <div>
            Phone<span id="red_star">*</span>
          </div>
          
          
            <input
              className="input_isd"
              type="text" required
              placeholder="ISD code" onChange={(e)=>setISD(e.target.value)}
              value={ISD}
            />
            
            <input
              className="input_phone" required
              type="text"
              placeholder="99876 54321" onChange={(e)=>setPhone(e.target.value)}
              value={Phone}
            />{" "}
       
        </div>
      </div>


      <div id="conrow">
            <div>
              <div>
                Linkedin Profile<span id="red_star">*</span>
              </div>
              <input
                className="input"
                type="text"
                required
                placeholder="Linkedin URL"
                onChange={(e) => setLinkedin(e.target.value)}
                value={Linkedin}
              />
              
            </div>

            <div className="right_flex">
              <div >
                Applying for<span id="red_star">*</span>
              </div>

              <div >
                
                <div  style={{marginTop:'5px'}}>

                <input
                  name="Type"
                  type="radio"
                  value="Internship"
                  onChange={(e) => setType(e.target.value)}
                /> Internship</div>
                <div>
                  <input
                 
                    type="radio"
                    name="Type"
                    onChange={(e) => setType(e.target.value)}
                    value="Full Time"
                  /> Full Time
                  
                </div>
              </div>
            </div>
          </div>
    



          <div id="conrow">
            <div>Total years of experience<span id="red_star">*</span>
                          <div  style={{marginTop:'5px'}}>
                <input
                  name="Experience"
                  type="radio"
                  value="0-3"
                  onChange={(e) => setExperience(e.target.value)}
                /> 0 to 3 years</div>
                <div>
                  <input
                 
                    type="radio"
                    name="Experience"
                    onChange={(e) => setExperience(e.target.value)}
                    value="3-7"
                  /> 3 to 7 years
                </div>

                <div>
                  <input
                 
                    type="radio"
                    name="Experience"
                    onChange={(e) => setExperience(e.target.value)}
                    value="7-15"
                  /> 7 to 15 years
                </div>

                <div>
                  <input
                 
                    type="radio"
                    name="Experience"
                    onChange={(e) => setExperience(e.target.value)}
                    value="15+"
                  /> Above 15 years
                </div>
                </div>


<br/>
                <div style={{marginRight:'5%'}}>Domain<span id="red_star">*</span>
                          <div  style={{marginTop:'5px'}}>
                <input
                  name="Domain"
                  type="radio"
                  value="Manufacturing"
                  onChange={(e) => setDomain(e.target.value)}
                /> Manufacturing</div>
                <div>
                  <input
                 
                    type="radio"
                    name="Domain"
                    onChange={(e) => setDomain(e.target.value)}
                    value="Production"
                  /> Production Design/Development
                </div>

                <div>
                  <input
                 
                    type="radio"
                    name="Domain"
                    onChange={(e) => setDomain(e.target.value)}
                    value="Accounts"
                  /> Accounts
                </div>

                <div>
                  <input
                 
                    type="radio"
                    name="Domain"
                    onChange={(e) => setDomain(e.target.value)}
                    value="R&D"
                  /> Research & Development
                </div>

                <div>
                  <input
                 
                    type="radio"
                    name="Domain"
                    onChange={(e) => setDomain(e.target.value)}
                    value="Software"
                  /> Software
                </div>

                <div>
                  <input
                 
                    type="radio"
                    name="Domain"
                    onChange={(e) => setDomain(e.target.value)}
                    value=" Sales & Marketing"
                  />  Sales & Marketing
                </div>
                </div>

            </div>


            <div id="conrow">
          <div style={{marginTop:'25px'}}>Previous Work experience</div>
          <textarea name = 'desc' rows="7" cols="30" className="text_area" placeholder="Describe your previous work experience (Max limit : 1000 characters)" onChange={(e)=>setDescription(e.target.value)}
          value={Description} maxlength="1000"></textarea>
        </div>









 <button id='apply_btn' type="submit" >Apply</button>
        <br/>
       </form>
   
       </div>
    <Footer/>
  
    </div>
    
  );
};

export default Apply