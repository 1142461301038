import React from "react";
import ps_banner from "../../assets/paint_sprayers/paint_sprayer_banner.png";
import ps_banner_mobile from "../../assets/paint_sprayers/ps_banner_mobile.png";

function Banner() {
  return (
    <div>
      <div id="banner_mobile_hid">
      <h1 id="banner_h1">AIRLESS PAINT SPRAYERS</h1>
      <p id="ps_banner_p">
        Experienced contractors and painting professionals know the difference.
        For high production rates and top performance, count on durable,
        easy-to-maintain Pradco battery and electric airless sprayers.
      </p></div>
      <img src={ps_banner} alt="Home Owner" id="banner_soln" />
      <img src={ps_banner_mobile} alt="Home Owner" id="banner_soln_mobile" />

      <h1 id="title_1">WORK SMARTER. FINISH MORE PROJECTS.</h1>
     
    </div>
  );
}

export default Banner;
