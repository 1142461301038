import React from "react";
import quality from "../../assets/industry/quality.png";
import technical from "../../assets/industry/technical.png";
import ROI from "../../assets/industry/ROI.png";
function Difference() {
  return (
    <div>
        <h2 id="diff_title">THE PRADCO DIFFERENCE</h2>
      <div id="content-3-1">
        <div id='sec-3-2nd'>
          <img src={quality} className='sec3_img'/>
          <h3 id="sec-3-subt">QUALITY</h3>
          <p id="cont3-p-1">
            Pradco provides high quality products to industries like yours
            around the world. Our success is based on a foundation of
            long-lasting, dependable equipment that you can trust.
          </p>
        </div>

        <div id='sec-3-2nd'>
          <img src={technical}  className='sec3_img' />
          <h3 id="sec-3-subt">TECHNICAL INNOVATION</h3>
          <p id="cont3-p-1">
            Built upon decades of delivering results to our customers, Pradco
            makes substantial investments in research in development year after
            year to ensure you get innovative products that take your
            manufacturing operations to the next level.
          </p>
        </div>

        <div  id='sec-3-2nd'>
          <img src={ROI} className='sec3_img' />
          <h3 id="sec-3-subt">RETURN ON INVESTMENT</h3>
          <p id="cont3-p-1">
            We are committed to providing you with high quality products at
            lower costs, which helps you improve customer satisfaction and
            enhance your brand while driving increased revenues for your
            business
          </p>
        </div>
      </div>
    </div>
  );
}

export default Difference;
