import React from 'react'
import {Header, Footer, HeaderPC} from '../components'
import Banner from '../components/Accessories/banner'
import '../components/Accessories/accessories.css'
import OnePass from '../components/Accessories/onePass'

function Accessories() {
  return (
    <div className='mobile_view'>
        <Header/>
        <HeaderPC/>
        <Banner/>
        <OnePass/>
        <Footer/>
    </div>
  )
}

export default Accessories