import React from "react";
import work_smarter from "../../assets/paint_sprayers/work_smarter.png";

function AirlessPaintSprayer() {
  return (
    <div id="airless_grey">
      <div id="pic-cont">
        <img src={work_smarter} alt="Work Smarter" />

        <div id="content-r">
          <h2>WHY A PRADCO AIRLESS PAINT SPRAYER?</h2>
          <p id="img-cont-p">
            Painting with an electric airless sprayer is faster, better and
            easier than painting with a brush or roller. You’ll see a marked
            difference in the finish quality with an airless sprayer and you
            won’t be spending time cleaning brushes and rollers. Pradco has
            introduced airless paint sprayers that deliver high performance and
            maximum productivity you need to grow your business. Whether you’re
            a contractor focused on small residential or a large contractor
            tackling the biggest projects every day, we have a configuration
            that’s designed to meet your needs. Pradco airless paint sprayers
            are designed and built in India using the highest-quality parts and
            backed by the industry’s leading warranty. All Pradco sprayers are
            100% factory-tested and field-proven so you can be sure your money
            is going toward the best professional paint sprayer you can
            purchase.
          </p>
        </div>
      </div>

      <div id="cont3">
        <div id='subcont'>
          <h2>MAXIMUM PERFORMANCE & UNMATCHED PRODUCTIVITY</h2>
          <p>
            Time is money — especially for contractors. Pradco is committed to
            professional contractors by designing high-performance sprayers that
            maximize your productivity on the job. Choose from battery or
            electric sprayers to grow your business and keep you profitable
          </p>
        </div>
        <div id='subcont'>
          <h2>INDUSTRY LEADING EQUIPMENT QUALITY & WARRANTY</h2>
          <p>
            We stand behind every piece of equipment we manufacture with the
            industry’s strongest warranty. Spray with the confidence that you
            will probably never need to use Pradco’s warranty, but if you do —
            we will be there.
          </p>
        </div>
        <div id='subcont'>
          <h2>A SUPERIOR AIRLESS FINISH EVERY TIME</h2>
          <p>
            Pradco airless paint sprayers deliver the best combination of speed
            and a high-quality finish. Our airless technology ensures a uniform
            and flawless coat of paint coverage on all surfaces whether you're
            painting interiors or exteriors.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AirlessPaintSprayer;
