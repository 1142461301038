import React from 'react'
import '../components/Contractor/contractor.css'
import { Header, Footer, HeaderPC } from '../components'
import Banner from '../components/Contractor/banner'
import C_cards from '../components/Contractor/C_cards'
import Equipment from '../components/Contractor/Equipment'

function Contractor() {
  return (
    <div className='mobile_view'>
      <Header/>
      <HeaderPC/>
        <Banner/>
        <C_cards/>
        <Equipment/>
        <Footer/>
    </div>
  )
}

export default Contractor