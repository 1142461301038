import React from "react";
import decks from "../../assets/home_owner/decks.png";
import arch from "../../assets/contractor/arch_coating.png";
import eifs from "../../assets/contractor/EIF.png";
import drywall from "../../assets/contractor/drywall.png";
import waterproofing from "../../assets/contractor/waterproofing.png";
import roof_coating from "../../assets/contractor/roof_coating.png";
import mortar from "../../assets/contractor/mortar.png";
import pavement from "../../assets/contractor/pavement.png";
import disinfectant from "../../assets/contractor/disinfectant.png";

function C_cards() {
  return (
    <div className="cbackground-grey">
      <div className="card-list">
        {/* CARD 1  */}

        <div className="card">
         
            <img src={arch} className="card-image" />
            <div className="card34_title">ARCHITECTURAL PAINTING & COATING</div>
            <p className="card_content">
              We design and manufacture an extensive line of professional paint
              sprayers and fine finish sprayers and guns for architectural
              painting and coating applications.
            </p>
        
        </div>

        {/* CARD 2 */}
        <div className="card">
         
            <img src={eifs} className="card-image" />
            <div className="card34_title">EIFS, RENDER & EXTERIOR TEXTURE</div>
            <p className="card_content">
              Professional contractors turn to Pradco for high performing,
              reliable EIF, plaster and exterior texture sprayers and mixers.
            </p>
        
        </div>

        {/* CARD 3 */}
        <div className="card">
         
            <img src={drywall} className="card-image" />
            <div className="card34_title">
              DRYWALL FINISHING & INTERIOR TEXTURE
            </div>
            <p className="card_content">
              Pradco manufactures a broad line of sprayers designed for Level 5
              finishing and skim coating, as well as interior textures.
            </p>
        
        </div>

        {/* CARD 4 */}
        <div className="card">
         
            <img src={waterproofing} className="card-image" />
            <div className="card34_title">WATERPROOFING</div>
            <p className="card_content">
              Pradco’s waterproofing sprayers for the professional can handle
              any size job you have in mind. We have your back.
            </p>
        
        </div>
      </div>

      <div className="card-list">
        {/* CARD 1  */}

        <div className="card">
         
            <img src={roof_coating} className="card-image" />
            <div className="card34_title">ROOF COATING</div>
            <p className="card_content">
              Explore Pradco’s line of roof coating sprayers and spray foam roof
              equipment. Designed for roof coating projects large and small.
            </p>
        
        </div>

        {/* CARD 2 */}
        <div className="card">
         
            <img src={mortar} className="card-image" />
            <div className="card34_title">MORTAR</div>
            <p className="card_content">
              Pradco designs and manufactures mortar mixers, pumps and sprayers
              for professional contractors. Built for the most demanding
              applications.
            </p>
        
        </div>

        {/* CARD 3 */}
        <div className="card">
         
            <img src={pavement} className="card-image" />
            <div className="card34_title">PAVEMENT & FIELD MARKING</div>
            <p className="card_content">
              From light duty field markers to heavy duty pavement equipments,
              Pradco equipment delivers lower paint consumption and
              professional, bright lines on any field, indoors or outdoors.
            </p>
        
        </div>

        {/* CARD 4 */}
        <div className="card">
         
            <img src={disinfectant} className="card-image" />
            <div className="card34_title">DISINFECTING & SANITISING</div>
            <p className="card_content">
              Pradco’s complete line of disinfectant sprayer accessories will
              help you finish disinfecting, sanitising and deodorising jobs
              faster and more efficiently.
            </p>
        
        </div>
      </div>
    </div>
  );
}

export default C_cards;
