import React from "react";
import RAC from "../../assets/spray_tip/RAC.png";

function RightTip() {
  return (
    <div>
      <h1 className="Rh1">CHOOSE THE RIGHT SPRAY TIP FOR YOUR PROJECT</h1>
      <p id="first_cont">
        Choosing a quality tip will determine the quality of your finish and
        help you save time and money. Each Pradco tip is 100% tested for flow
        rate to ensure the precision and quality needed to deliver a smooth,
        professional finish.
        <br />
        <br />
        Pradco offers the following airless spray tips for Homeowner and DIY
        models:
      </p>
      <br />
      <h2 className="subheading">SprayX Sprayers: RAC 5 Tips </h2>
      <p id="first_cont">
        Ideal for DIYers and Homeowners renovating their home or apartment. RAC 5
        tips delivers a consistent spray pattern to easily blend each spray pass.
        Experience less prep and clean-up time with reduced overspray. Ability to
        spray the thickest paints at a lower pressure. Reverse-A-Clean design
        makes it easy to clear tip clogs. With many tip sizes to select, its easy
        to find the right tip for the job at hand. Just review the table below
        to determine the size you need.
      </p>
      <br />

      <h2 className="subheading">TrueCoat 360: Tips</h2>
      <p id="first_cont">
        Reverse-A-Clean design makes it easy to clear tip clogs TrueCoat 360
        sprayers include both sizes of available tips, with 4 inch (10 cm) and
        12 inch (30 cm) fan widths
      </p>
      <br />
      <img src={RAC} id="rac" />
      <br />
      <br />
      <br />

      <h2 className="subheading">UNDERSTANDING THE NUMBERS</h2>
      <p id="first_cont">
        The first digit is half of the fan width, the "5" in "517" equals a 10
        inch (25 cm) spray fan width (5 x 2 = 10) The last two digits are the
        size of the tip opening in thousandths of an inch, the "17" in "517"
        means the tip hole size is .017 inch
      </p>
      <br />

      <h2 className="subheading">SPEED ADVICE</h2>
      <p id="first_cont">
        <ul>
          <li>
            Use smaller tip size when applying thinner materials such as stains
            and enamels
          </li>
          <li>
            Use larger sizes for thicker materials such as enamels and
            water-based interior and exterior paints
          </li>
          <li>
            The larger the size of the tip hole, the faster the material leaves
            the gun
          </li>
          <li>A 313 tip sprays faster than a 411 tip</li>
        </ul>
      </p>
      <br />
      <h2 className="subheading">UNDERSTANDING TIP WEAR</h2>
      <p id="first_cont">
        Choosing the right spray tip is essential for ensuring a quality finish
        -- no matter which coating material is being sprayed. However, spray
        tips will wear with normal use.
        <br />
        <br />
        When a tip wears, the orifice size increases and the fan width
        decreases. This causes more paint to hit a smaller area, which wastes
        paint and slows productivity. It’s important to replace a tip when it
        gets worn to ensure a precise spray pattern, maximum productivity and
        quality finish.
      </p>
      <br />

      <h2 className="subheading">TIP WEAR ADVICE</h2>
      <p id="first_cont">
        Tip life varies by coating. Recommended replacement times: 
        <ul>
            <li>Water-based -
        Replace after 55-150 Litres (15-40 gallons)</li>
        <li>Enamels & Stains - Replace
        after 130-225 Litres (35-60 gallons) </li> 
        <li>Extend tip life by spraying at the
        lowest pressure that breaks up the coating, atomising it to a complete
        spray pattern.</li><li> Do not increase the pump pressure – this only wastes
        paint and causes unnecessary pump component wear.</li> <li>Always check the
        Sprayer Manual first to determine which materials are compatible for use
        with that model.</li>
        </ul>
      </p>
      <br/><br/><br/>
      <br />
    </div>
  );
}

export default RightTip;
