import React from 'react'

function Privacy() {
  return (
    <div className='terms'>
          <h1 className='introduction'>PRIVACY POLICY</h1><br/>
          
          <div className='parallel'>
            <div>
       <h2 className='headings' id='introduction'>INTRODUCTION TO PRIVACY POLICY</h2>
        <p>
          Pradco Private Limited. (“Pradco”) and its affiliates and subsidiaries
          (together “we”, “our”, or “us”) is committed to protecting your
          privacy. This notice describes the categories of Personal Data we
          process in connection with your use of any of the websites available
          at www.pradco.in (“Websites”) and the services, features or content we
          offer (“Services”), the purposes for which Personal Data is collected,
          the parties with whom we share it and the security measures we take to
          protect your data. It also informs you about your rights and choices
          with respect to your Personal Data, and how you can contact us to
          inquire about our data protection practices. Please read this notice
          carefully. In the event you disagree with any provision in this
          notice, please do not use Websites or provide any Personal Data. This
          notice may change from time to time, for more information about notice
          amendments see Section XIII below.
        </p>

        <h2 className='headings' id='infowecollect'>INFORMATION WE COLLECT AND HOW WE USE IT</h2>

        <p>“Personal Data” means any information relating to an identified or
          identifiable natural person; an identifiable natural person is one who
          can be identified, directly or indirectly, in particular by reference
          to an identifier such as a name, an identification number, location
          data, an online identifier or to one or more factors specific to the
          physical, physiological, genetic, mental, economic, cultural or social
          identity of that natural person.<br />
          When visiting our Websites you have the option to provide us with
          Personal Data relating to you. Additionally, when you use our Services
          we automatically collect certain information about you and your
          internet usage. The specific categories of Personal Data concerned and
          the sources from which we obtain them are linked to the way you
          interact with our Websites and Services. More information about the
          categories of Personal Data and the ways in which we collect it are
          described below.<br />
          (1) Personal Data You Give to Us. Our Websites offer you the
          possibility of (i) contacting us via an online request form; (ii)
          signing up to receive email alerts; and (iii) registering for a
          Customer Information System (“CIS”), Pradco Partner Portal ("Partner
          Portal"), or a Pradco Distributor Interface (“PDI”) account. The
          Personal Data thereby collected includes: <br /><br />If you are a
          Pradco distributor:
        </p>

        <ul>
          <li>
            Contact information about you such as your first and last name,
            email address, your phone and fax number, your zip/postal code, your
            state and country of residence;
          </li>
          <li>
            Professional information related to you and to those of your
            employees with access to the CIS, Partner Portal, or PDI account
            consisting of your company name, first and last name, (function)
            title, email address, the company’s general manager’s name, the
            company’s website, the company’s address, the shipping address and
            your Pradco account number;
          </li>
          <li>
            Your and your employee’s CIS, Partner Portal, or PDI passwords; and
          </li>
          <li>
            Contract information (provided the contract party is the end
            consumer) such as bank account information, creditworthiness, terms
            of payment and financing.
          </li>
        </ul>
      
        <p>
          The obligatory fields are marked as such on the interface. The use of
          and the interaction with our Websites or Services is subject to the
          provision of this information.
        </p>
        
        <p>If you are a general user:</p>
    
        <ul>
          <li>
            Contact information such as your first and last name, email address,
            your phone number, your zip/postal code, your city, state and
            country of residence; and
          </li>
          <li>Professional information such as your company name.</li>
        </ul>
     
        <p>
          (2) Personal Data We Automatically Collect. As you navigate through
          our Websites or when you interact with our Services, we use automatic
          data collection technologies to collect certain Personal Data about
          your device, browsing activity, and patterns, including:
        </p>
        <ul>
          <li>
            Log usage data of your visits to our Websites and use of our
            Services, including technical session and connection information,
            resources that you access, traffic data, location data, date and
            time of access and frequency;
          </li>
          <li>
            Personal Data about your computer and internet connection, hardware
            and software, including your IP address, operating system, host
            domain, browser type, language settings, web pages you viewed on our
            Websites, search terms and times of your visit; and
          </li>
          <li>
            Details of referring websites (URL) and web pages you visited prior
            to ours.
          </li>
        </ul>
        <p>(3) Cookies Used On Our Websites. We use cookies, beacons
        and similar technologies on our Websites. Cookies are small data files
        that are stored on a user’s computer for record keeping purposes. We use
        them in public areas of our Websites, as well as on the CIS and PDI
        areas of the Websites.<br /><br />
        Our Websites use single-session (temporary) and multi-session
        (persistent) cookies. Temporary cookies last only as long as your web
        browser is open, and are used for technical purposes such as enabling
        better navigation on our Websites. Once you close your browser, the
        cookie disappears. Persistent cookies are stored on your computer for
        longer periods and are used for purposes which include tracking the
        number of unique visitors to our Websites and Personal Data such as the
        number of views a page gets, how much time a user spends on a page, and
        other pertinent web statistics. This Personal Data identifies your
        browser to our servers when you visit the Websites. <br /><br />Most web
        browsers are set to accept cookies by default. If users prefer, they can
        usually choose to set their browsers to remove and reject cookies. In
        some cases, removing or rejecting cookies may affect certain features or
        services on our Websites. If you want to disable the use of cookies or
        remove them from your computer, you can disable or delete them at any
        time using your browser (consult your browser's "Help" menu to learn how
        to delete cookies). Below you will find a detailed list of the
        first-party and third-party cookies we use on our Website:<br /><br />
        Social Media Plugins. When using our Websites we allow you to share
        information with social media sites and to access our social media
        profiles through so-called plugins. Social networks are able to retrieve
        Personal Data through those plugins, even if you don’t interact with
        them. Moreover, if you are logged onto a social network while visiting
        our Websites with social plugins imbedded in them, the network can
        collect and store information about such visit and link it to your
        social network user account. As we have no control over the data
        collected by social media networks through their plugins, we encourage
        you to read their applicable data privacy policies to learn more about
        them.<br /><br />
        Once you choose to share information from our Websites on social media
        or when you connect with our social media profiles through the plugins,
        those social media sites allow us to automatically access Personal Data
        retained by them about you consisting of content viewed by you, content
        liked by you and information about the advertisements you have been
        shown or have clicked on. You can restrict our access to your Personal
        Data by changing your privacy setting on the respective social media
        site.<br /><br />
        Lastly, you can access our Websites via a third-party service, e.g. from
        our profiles on social networks. In those cases, we collect Personal
        Data from your social media user account consisting of your first and
        last name, email address and phone number and any other information you
        have made public.<br /></p>

        <h2 className='headings' id='howweuse'>HOW WE USE YOUR PERSONAL DATA</h2>
        <p>
          We will only process your Personal Data for specific, explicit and
          legitimate purposes. We will not process your Personal Data for any
          further purposes than the ones the data was originally intended for,
          unless the new purpose is compatible with the original one. In the
          absence of compatibility, the processing of data for further purposes
          is subject to your prior explicit consent.
          <br />We process the Personal Data you provide us with for the
          purposes listed below:<br />
          If you are a Pradco distributor:
        </p>
        <ul>
          <li>
            Evaluate your eligibility for a CIS, Partner Portal, or PDI account
            and manage your account; 
          </li>
          <li>
            Communicate with you upon a request for information, support or
            customer service; and
          </li>
          <li>
            Inform you about our products and services, events, newsflashes,
            newsletters, surveys, purchases of services and/or products.
          </li>
        
        </ul>
        <p>If you are a general user:</p>
        <ul>
          <li>
            Inform you about our products and services, events, newsflashes,
            newsletters, surveys, purchases of services and/or products;
          </li>
          <li>
            Communicate with you upon a request to offer information, product
            support or for the purpose of customer service;
          </li>
          <li>
            Log the Pradco products you have purchased, if applicable (via
            Product Registration Form submitted by you); and
          </li>
          <li>
            Send you email alerts to inform you about publications on your
            investors’ domain.
          </li>
        </ul>
        
        <h2 className='headings' id='children'>CHILDREN</h2>
        <p>
          Our Websites are not intended for children and we have no intention of
          collecting Personal Data from individuals under eighteen years of age.
          If a child has provided us with Personal Data, a parent or a guardian
          of that child may contact us to request to have such information
          deleted by emailing to contact@pradco.in
        </p>

        <h2 className='headings' id='revisions'>REVISIONS TO OUR DATA PROTECTION NOTICE</h2>
        <p>
          We reserve the right to amend this notice from time to time consistent
          with applicable data protection laws and regulations. Any changes to
          this notice will be posted on this page. If we make material changes
          to how we treat your Personal Data, we will notify you through a
          notice on the Website home page via a separate banner related to this
          Data Protection Notice.
        </p>
        <h2 className='headings' id='iot'>PRADCO IOT DEVICE DATA PROTECTION NOTICES</h2>
        <p>
          We reserve the right to collect that data of your
          device and locations from IoT for providing a better service. The chip
          will collect the information and pass it to our servers. The data’s
          can be deleted at any time from the customer’s end.
        </p>
        <h2 id="transferofinformation" className='headings'>TRANSFER OF INFORMATION</h2>
        <p>
          We do not share your Personal Information with any third party apart
          from financial institutions such as banks, RBI or other regulatory
          agencies (as may be required) and to provide you with services that we
          offer through Pradco, conduct quality assurance testing, facilitate
          creation of accounts, provide technical and customer support, or
          provide specific services, such as synchronization of your contacts
          with other software applications, in accordance with your
          instructions. These third parties are required not to use your
          Personal Information other than to provide the services requested by
          you.
        </p>
        <p>
          We may share your Personal Information with our parent company,
          subsidiaries, joint ventures, or other companies under a common
          control (collectively, the "<strong>Affiliates</strong>") that we may
          have now or in the future, in which case we will require them to honor
          this Privacy Policy. If another company acquires our company or our
          assets, that company will possess your Personal Information, and will
          assume the rights and obligations with respect to that information as
          described in this Privacy Policy. We may disclose your Personal
          Information to third parties in a good faith belief that such
          disclosure is reasonably necessary to (a) take action regarding
          suspected illegal activities; (b) enforce or apply our terms and
          conditions and Privacy Policy; (c) comply with legal process, such as
          a search warrant, subpoena, statute, or court order; or (d) protect
          our rights, reputation, and property, or that of our Users,
          Affiliates, or the public. Please note that we are not required to
          question or contest the validity of any search warrant, subpoena or
          other similar governmental request that we receive.
        </p>
        <p>
          We may disclose information in the aggregate to third parties relating
          to User behavior in connection with actual or prospective business
          relationship with those third parties, such as advertisers and content
          distributors. For example, we may disclose the number of Users that
          have been exposed to, or clicked on, advertising banners.
        </p>
        <h2 className='headings'>LINKS</h2>
        <p>
          References on this Website to any names, marks, products or services
          of third parties or hyperlinks to third party websites or information
          are provided solely for your convenience and do not in any way
          constitute or imply our endorsement, sponsorship or recommendation of
          the third party, information, product or service. Except as set forth
          herein, we do not share your Personal Information with those third
          parties, and are not responsible for their privacy practices. We
          suggest you read the privacy policies on all such third party
          websites.
        </p>
        <h2 className='headings' id='useraccess'>USER ACCESS OF PERSONAL INFORMATION</h2>
        <p>
          As a registered Pradco User, you can modify some of your Personal
          Information and your privacy preferences by accessing the "Account"
          section of this Website.
        </p>
        <h2 id="security" className='headings'>SECURITY</h2>
        <p>
          Your account is password protected. We use industry standard measures
          to protect the Personal Information that is stored in our database. We
          limit the access to your Personal Information to those employees and
          contractors who need access to perform their job function, such as our
          customer service personnel. If you have any questions about the
          security on Pradco, please contact us at 
          contact@pradco.in
        </p>
        <p>
          You hereby acknowledge that Pradco is not responsible for any
          intercepted information sent via the internet, and you hereby release
          us from any and all claims arising out of or related to the use of
          intercepted information in any unauthorized manner.
        </p>
        <h2 id="termsandmodifications" className='headings'>
          TERMS AND MODIFICATIONS TO THIS PRIVACY POLICY
        </h2>
        <p>
          Our Privacy Policy is subject to change at any time without notice. To
          make sure you are aware of any changes, please review this policy
          periodically. These changes will be effective immediately on the Users
          of Pradco. Please note that at all times you are responsible for
          updating your Personal Information, including to provide us with your
          most current e-mail address.
        </p>
        <p>
          If you do not wish to permit changes in our use of your Personal
          Information, you must notify us promptly that you wish to deactivate
          your account with us. Continued use of Pradco after any change/
          amendment to this Privacy Policy shall indicate your acknowledgement
          of such changes and agreement to be bound by the terms and conditions
          of such changes.
        </p>
        <h2 className='headings' id='applicablelaw'>APPLICABLE LAW</h2>
        <p>
          Your use of this Website will be governed by and construed in
          accordance with the laws of India. The Users agree that any legal
          action or proceedings arising out of your use may be brought
          exclusively in the competent courts/ tribunals having jurisdiction in
          Coimbatore in India and irrevocably submit themselves to the
          jurisdiction of such courts/ tribunals.
        </p>
        <h2 id="grievanceredressal" className='headings'>COMPLAINTS AND GRIEVANCE REDRESSAL</h2>
        <p>
          <strong
            >Any complaints or concerns with regards to content of this Website
            or comment or breach of these Terms of Use or any intellectual
            property of any User, instances of customer grievances, regulatory
            queries and clarifications shall be informed/ communicated to the
            Grievance Cell at the co-ordinates mentioned below in writing or by
            way of raising a grievance ticket through email mentioned
            below:</strong
          >
        </p>
        <p><strong>Pradco Private Limited</strong></p><br/><br/>
    </div>
    
    <div className='quick_links'>
          <a href='#introduction'>INTRODUCTION TO PRIVACY POLICY</a>
          <a  href='#infowecollect'> INFORMATION WE COLLECT & HOW WE USE IT</a>
          <a  href='#howweuse'>  How we use your personal data</a>
          <a href='#children'>  Children</a>
          <a href='#revisions'>  Revisions to our data protection notice</a>
          <a href='#iot'> Pradco IOT Device Protecction Notices</a>
          <a href='#transferofinformation'>  Transfer of information</a>
          <a href='#links'>  Links</a>
          <a href='#useraccess'> User access of Personal Information</a>
          <a href='#security'> Security</a>
          <a href='#termsandmodifications'>Terms and modifications to this Privacy Policy</a>
          <a href='#applicablelaw'> Applicable law</a>
          <a href='#grievanceredressal'>  Grievance Redressal</a>
          </div>

    </div></div>
  )
}

export default Privacy