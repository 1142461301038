import React from "react";
import { Header, Footer, HeaderPC } from "../components";
import "./home.css";
import "../components/home/home_mobile.css";
import Banner from "../components/home/home_banner";
import Products from "../components/home/products_soln";
import Values from "../components/home/values";
import Careers from "../components/home/Careers";
import Customer from "../components/home/customer";
import Funded from "../components/home/funded";
function Home() {
  return (
    <div className='mobile_view'>
       <HeaderPC />
       <Header/>
      
       <Banner/> 
       <Products />
      
        <Values /> 
       
      <Careers/>
     
      <Customer />
      <Funded/>
      
      <Footer />  
     
    </div>
  );
}

export default Home;
