import React from "react";
import agri_banner from "../../assets/agri_pressure/agri_banner.png";
import clean from "../../assets/agri_pressure/clean.png";
import agri_banner_mobile from "../../assets/vehicle/vehicle_banner_mobile.png";

function Banner() {
  return (
    <div>
      <div id="banner_mobile_hid">
        <h1 id="vbanner_h1">
          PROFESSIONAL PRESSURE <br />
          WASHERS
        </h1>
        <p id="vbanner_p">
          Professional contractors turn to long-lasting and reliable Graco
          professional pressure washers for medium to heavy-duty jobs. From just
          a few hours a week to regular daily use, Graco pressure washers
          deliver reliable power and performance to get your cleaning jobs done
          right, every time.
        </p>
      </div>
      <img src={agri_banner} alt="Home Owner" id="banner_soln" />
      <img src={agri_banner_mobile} alt="Home Owner" id="banner_soln_mobile" />

      <h1 id="vtitle_1">
        IF IT’S AN IMPOSSIBLE CLEANING JOB, IT’S TIME FOR PRADCO
      </h1>
      <div className="cleaning-flex">
        <img src={clean} alt="Cleaning" id="val_img" />
        <div>
            <h3 id="val_tag">Depend on Pradco for Your Professional Pressure Washer Needs</h3>
        </div>
      </div>
    </div>
  );
}

export default Banner;
