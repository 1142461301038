import React from 'react'
import './contractor.css'
import c_banner from '../../assets/contractor/contractor.png'
import c_banner_mobile from '../../assets/contractor/contractor_mob.png'
function cBanner() {
  return (
    <div> 
       
        <img src={c_banner_mobile} alt="Home Owner" id="cbanner_soln_mobile" />
        <div id="banner_mobile_hid">
        <h4 class="cbanner_tag">DESIGNED FOR PROFESSIONALS, BY PROFESSIONALS</h4>
    <h1 id="cbanner_h1">
      PRODUCTS FOR <br />
      CONTRACTORS
    </h1>
    <p id="cbanner_p">
    Contractors prefer Pradco equipment for unmatched on-the-job productivity and for proven quality. Explore all our products designed for contractors who want the best.
    </p></div>
    <img src={c_banner} alt="Home Owner" id="cbanner_soln" /> 
  
    <div id='title_sec'>
    <h1 id="ctitle_1">PRADCO CONTRACTOR PRODUCTS</h1>
    <p id="ctag">Pradco products are designed and built to the high standards for professional contractors.</p>
   </div>
    </div>
    
  )
}

export default cBanner