import React from "react";
import always_on from "../../assets/vehicle/always_on.png";

function AlwaysOn() {
  return (
    <div>
      <img id="always_img" src={always_on} />
      <p id="img">
        Pradco Auto equipment solutions provide certainty for today’s modern
        equipment manufacturers, managers and operators seeking continuous
        uptime and optimal productivity from the machines they rely on daily.
      </p>

      <div id="content-3">
        <div>
          <h3>EXPERIENCED ENGINEERING</h3>
          <p id="cont3-p">
            Designed for virtually any type of mobile or industrial environment
            by our experienced group of engineers — Pradco auto-lubrication
            offers a significant upgrade to manual lubrication.
          </p>
        </div>

        <div>
          <h3>INNOVATION AND RELIABILITY</h3>
          <p id="cont3-p">
            Our systems capture critical data that can be used to simplify other
            tasks for key decision-makers. As a result, our comprehensive
            automatic lubrication offering features market-leading innovation
            and reliability.
          </p>
        </div>

        <div>
          <h3>TIME-TESTED, QUALITY PRODUCTS</h3>
          <p id="cont3-p">
            Business owners turn to Pradco for the most reliable and
            highest-quality service lube equipment in the industry. We build our
            products and systems to last far beyond the competition.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AlwaysOn;
