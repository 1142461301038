import React from "react";
import acc_banner from "../../assets/accessories/accessories_banner.png";
import catalogue_banner_mobile from "../../assets/catalogue/catalogue_banner_mobile.png";


function Banner() {
  return (
    <div>
        <div id="banner_mobile_hid">
      <h1 id="abanner_h1">PRODUCT CATALOGUE</h1></div>
    
      <img src={acc_banner} alt="Home Owner" id="banner_soln" />
      <img src={catalogue_banner_mobile} alt="Home Owner" id="banner_soln_mobile" />
    </div>
  );
}

export default Banner;
