import React from 'react'
import { Header, Footer, HeaderPC } from '../components'
import Banner from '../components/AgriPressure/banner'
import '../components/AgriPressure/agri.css'
function AgriPressure() {
  return (
    <div>
         <HeaderPC/>
        <Header/>
        
        <Banner/>
        
       
        <Footer/>
        
    </div>
  )
}

export default AgriPressure