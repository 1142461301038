import React from 'react'
import { Header,Footer, HeaderPC } from '../components'
import Privacy from '../components/Privacy/privacy'

function PrivacyPolicy() {
  return (
    <div className='mobile_view'>
      <Header/>
      <HeaderPC/>
      <Privacy/>
      <Footer/>
    </div>
  )
}

export default PrivacyPolicy