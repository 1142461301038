import React from 'react'
import customer from '../../assets/home/customer.png';
function Customer() {
  return (
    
    <div className='values_customers'>
    <h2 id='val_title'>CUSTOMER SERVICE</h2>
    <hr id='blue-line'/>
    
    <div className='val_cust_content'>
    <img src={customer} id='cust_img'/>
    <div  id='val_content'>
      <h2  id="val_tag">WE'RE HERE TO HELP</h2>
    
      <p >A+ Customer Service for any  Pradco product is only a call or e-mail away.

</p>
<a className="learn_more" href='/contact'>
        LEARN MORE {">"}
        <hr id="learn-blue-line" />
      </a> 
</div>
      
    </div>
    
    
    </div>
  )
}

export default Customer;