import React from 'react'
import {Header, Footer, HeaderPC} from '../components'
import Banner from '../components/Careers/banner'
import Title from '../components/Careers/title'
import Team from '../components/Careers/team'
import SharedValues from '../components/Careers/sharedval'
import Positions from '../components/Careers/positions'
import '../components/Careers/careers.css'

function Careers() {
  return (
    <div className='mobile_view'>
        <Header/>
        <HeaderPC/>
        <Banner/>
        <Title/>
        <Team/>
        <br/>
        <SharedValues/>
        {/* <Positions/> */}
        <h2 id='center'>Search for Open Positions</h2>
        <p  id="cartag">Pradco's global headquarters is located in Tamil Nadu, India. Our Regional headquarters are located in Bengaluru, Hyderabad, Kochi, Kolkata, Mumbai and New Delhi in addition to other sales territory job openings.</p>
        <div id='btn_center'>
          <a href='jobdescription'>
        <button id='job_btn'>
          SEARCH JOBS
        </button></a></div>

        <Footer/>
    </div>
  )
}

export default Careers